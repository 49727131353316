import React from 'react'
import logo from '../assets/salle_logo_land.png';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Lock, Mail, Visibility, VisibilityOff } from '@mui/icons-material';
import { auth } from "../database/config_firebase";
import { signInWithEmailAndPassword } from 'firebase/auth';
import Swal from 'sweetalert2';
import loadingSVG from '../assets/loading.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from "../actions/auth_actions";
import { getUserById } from '../database/users';
import colors from '../utils/colors';
import { useTheme } from '@mui/material/styles';

export default function Login() {

    const theme = useTheme();

    const dispatch = useDispatch();

    const handleLogin = (userData, userId) => {
        dispatch(login(userData, userId));
    };

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const navigate = useNavigate();

    const onLogin = async (e) => {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email.trim(),
                password
            );
            const user = userCredential.user;
            const resUser = await getUserById(user.uid);
            handleLogin(resUser, user.uid);
            if (resUser.role) {
                if (resUser.role === 'reports') {
                    navigate('/bingoReports')
                } else {
                    navigate("/home");
                }
            } else {
                navigate("/home");

            }
            Swal.close();
        } catch (error) {
            if (error.code === "auth/wrong-password") {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Contraseña incorrecta. Verifica tus credenciales.",
                });
            } else if (error.code === "auth/too-many-requests") {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Demasiados intentos fallidos. Inténtalo después.",
                });
            } else {
                console.log(error)
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Correo no encontrado. Verifica tus datos.",
                });
            }
            // setError(error)
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onLogin(event);
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: colors.blue
        }}>
            <div
                onKeyDown={handleKeyDown}
                style={{
                    width: '400px',
                    padding: '20px',
                    height: '400px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <img src={logo} alt="Logo" style={{ width: '300px' }} />
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Correo"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Mail sx={{
                                    color: colors.white
                                }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        color: colors.white,
                        backgroundColor: '#1212121F',
                        m: 1,
                        borderRadius: '10px',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // Quita el borde
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                            opacity: 1,
                        },
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }}
                />
                <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock sx={{
                                    color: colors.white
                                }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{
                                        color: colors.white
                                    }}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        color: colors.white,
                        backgroundColor: '#1212121F',
                        m: 1,
                        borderRadius: '10px',
                        width: '100%',
                        '& .MuiInputBase-input': {
                            color: 'white',
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: 'white',
                            opacity: 1,
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // Quita el borde
                            },
                        },
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }}
                />
                <Button
                    onClick={onLogin}
                    variant="contained"
                    sx={{
                        fontSize: '17px',
                        fontFamily: 'Nunito',
                        textTransform: 'none',
                        backgroundColor: colors.blueLight,
                        color: theme.palette.common.white,
                        m: 1,
                        width: '100%',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            backgroundColor: colors.blueLight,
                        },
                        '&:active': {
                            transform: 'scale(0.95)',
                            boxShadow: '0 2px 4px 1px rgba(33, 203, 243, .2)',
                        },
                    }}
                >
                    Iniciar Sesión
                </Button>
            </div>
        </div>
    )
}
