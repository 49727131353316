import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, useMediaQuery, Divider } from '@mui/material';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import ReactConfetti from 'react-confetti';
import colors from '../utils/colors';
import { useTheme } from '@emotion/react';
import logoBingo from '../assets/logo-bingo.svg'
import { getBingoData } from '../database/bingos';
import { CheckCircleOutlined, CheckOutlined } from '@mui/icons-material';


const ViewBingo = () => {
    const [bingoData, setBingoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchBingoData = async () => {
            try {
                const params = new URLSearchParams(window.location.search);

                const encryptedId = params.get('id');
                console.log("ID ENcode", encodeURI(encryptedId.replace(/ /g, '+')));

                console.log("Valor encriptado recibido de la URL:", encryptedId);

                console.log("Clave de encriptación utilizada:", process.env.REACT_APP_ENCRYPT_KEY);


                if (!encryptedId) {
                    setError('ID no proporcionado');
                    setLoading(false);
                    return;
                }

                // Decodificar el valor encriptado de la URL
                const decodedEncryptedId = encryptedId;

                console.log("Valor encriptado decodificado:", encryptedId);



                // // Desencriptar el ID
                // const bytes = CryptoJS.AES.decrypt(decodedEncryptedId, process.env.REACT_APP_ENCRYPT_KEY);
                // const decryptedId = bytes.toString(CryptoJS.enc.Utf8);W

                if (!encryptedId) {
                    setError('ID no proporcionado');
                    setLoading(false);
                    return;
                }



                const bytes = CryptoJS.AES.decrypt(encodeURI(encryptedId.replace(/ /g, '+')), '13579');
                const decryptedId = bytes.toString(CryptoJS.enc.Utf8);

                if (!decryptedId) {
                    setError('No se pudo desencriptar el ID.');
                    setLoading(false);
                    return;
                }

                console.log("ID desencriptado:", decryptedId);

                // Aquí puedes continuar con la lógica para obtener los datos del bingo
                const response = await getBingoData(decryptedId);
                setBingoData(response);
                setLoading(false);

            } catch (err) {
                console.error(err);
                setError('Error al obtener los datos del bingo.');
                setLoading(false);
            }
        };

        fetchBingoData();
    }, []);


    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
    }

    if (error) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Typography variant="h6" color="error">{error}</Typography></Box>;
    }

    return (
        <>
            <div style={{
                backgroundColor: colors.blue,
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                overflow: 'hidden',
                '&::-webkit-scrollbar': { display: 'none' },
                zIndex: 0
            }} />

            <div style={{
                position: 'absolute',
                backgroundColor: colors.blue,
                // top: 0,
                // left: 0,
                width: '99vw',
                height: '95vh',
                zIndex: 1,
                overflow: 'hidden',
                '&::-webkit-scrollbar': { display: 'none' },
            }}>
                <ReactConfetti
                    width={windowSize.width}
                    height={windowSize.height}
                    numberOfPieces={400}
                    gravity={0.01}
                    initialVelocityY={6}
                    // recycle={true}
                    colors={["#3DBD96BF", "#FDA5A5BF", "#FDA5A559", "#ECB43C73", "#56B8A5D9", "#ECB43CE5", "#56B8A599", "#3DBD9659", "#FDA5A5"]}
                    style={{
                        opacity: 0.7,
                    }}
                />
            </div>


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '90vh',
                backgroundColor: 'transparent',
                zIndex: 2,
                position: 'relative',
                p: isMobile ? 1 : 3,
                maxWidth: isMobile ? '100%' : 600,
                overflowY: 'auto',
                mx: 'auto', // Centra horizontalmente
                '&::WebkitScrollbar': { display: 'none' },
            }}>
                <center>
                    <img src={logoBingo} style={{
                        marginTop: '10px',
                        width: isMobile ? '60%' : '80%', // Cambia el tamaño en móviles
                    }} />
                </center>
                <Typography variant={isMobile ? "h6" : "h5"} gutterBottom sx={{
                    color: colors.yellow,
                    textAlign: 'center',
                    fontSize: isMobile ? '16px' : '24px', // Ajusta el tamaño de texto
                    marginBottom: isMobile ? '10px' : '20px'
                }}>
                    Código QR válido
                </Typography>
                <Box sx={{
                    backgroundColor: '#DFF2FF',
                    padding: isMobile ? 2 : 3, // Cambia el padding en móviles
                    borderRadius: 4,
                    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                    textAlign: 'center',
                    width: isMobile ? '90%' : '80%', // Ajusta el ancho
                    marginBottom: isMobile ? '20px' : '40px',
                }}>
                    <CheckCircleOutlined sx={{
                        fontSize: isMobile ? '60px' : '80px', // Ajusta el tamaño del icono
                        color: colors.green
                    }} />
                    <Typography sx={{
                        color: '#035A89',
                        fontSize: isMobile ? '14px' : '16px', // Ajusta el tamaño de texto
                        marginTop: isMobile ? '10px' : '20px'
                    }}>
                        Se verifica que el código QR leído es válido. A continuación se mostrarán los datos de compra.
                    </Typography>
                    <Divider sx={{
                        m: 2
                    }} />

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row', // En móviles, muestra en columna
                        alignItems: isMobile ? 'flex-start' : 'center', // Alinea los elementos en móviles
                    }}>
                        <Typography sx={{
                            color: '#035A89',
                            fontSize: isMobile ? '14px' : '16px', // Ajusta el tamaño de texto
                            marginBottom: isMobile ? '8px' : '0', // Espacio en móviles
                        }}>Nombre del comprador</Typography>
                        <Typography sx={{ fontSize: isMobile ? '14px' : '16px' }}>{bingoData?.name || ''} {bingoData?.lastName || ''}</Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row', // En móviles, muestra en columna
                        alignItems: isMobile ? 'flex-start' : 'center', // Alinea los elementos en móviles
                    }}>
                        <Typography sx={{
                            color: '#035A89',
                            fontSize: isMobile ? '14px' : '16px', // Ajusta el tamaño de texto
                            marginBottom: isMobile ? '8px' : '0', // Espacio en móviles
                        }}>Email del comprador</Typography>
                        <Typography sx={{ fontSize: isMobile ? '14px' : '16px' }}>{bingoData?.email || 'N/A'}</Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : 'row', // En móviles, muestra en columna
                        alignItems: isMobile ? 'flex-start' : 'center', // Alinea los elementos en móviles
                        marginBottom: isMobile ? 1 : 2,
                    }}>
                        <Typography sx={{
                            color: '#035A89',
                            fontSize: isMobile ? '14px' : '16px', // Ajusta el tamaño de texto
                            marginBottom: isMobile ? '8px' : '0', // Espacio en móviles
                        }}>Número de tarjeta</Typography>
                        <Typography sx={{ fontSize: isMobile ? '14px' : '16px' }}>{bingoData?.idCard || 'N/A'}</Typography>
                    </Box>

                    <Typography sx={{
                        color: colors.blue,
                        fontSize: isMobile ? '12px' : '14px',
                    }}>Bingo La Salle Temuco 2024.</Typography>

                </Box>
            </Box>


        </>
    );
};

export default ViewBingo;
