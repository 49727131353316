import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createFamily, createFamilyMulti, updateFamily } from '../database/family';
import Swal from 'sweetalert2';

export default function FinalDialogCreateFamily({ openDialog, onClose, onCloseBack, dataFamily, reloadData, clearInputs, isView, idFamily }) {
    const [responsible, setResponsible] = useState({
        apoderado: '',
        economico: ''
    });

    useEffect(() => {
        async function getData() {
            setResponsible(dataFamily.responsible ? dataFamily.responsible : {
                apoderado: '',
                economico: ''
            });
        }
        getData();
    }, [dataFamily]);

    return (
        <Dialog sx={{
            zIndex: 2
        }} open={openDialog} onClose={() => onClose()} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ backgroundColor: '#f5f5f5', color: '#06314e' }}>
                Seleccione el Apoderado y Responsable Económico
            </DialogTitle>
            <DialogContent sx={{ padding: '24px', backgroundColor: '#fafafa' }}>
                <Typography variant="body1" sx={{ marginBottom: '16px', color: '#555' }}>
                    A continuación deberás indicar quién será el apoderado del alumno, para fines escolares. Y quién será el responsable económico, para fines monetarios.
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" sx={{ color: '#06314e' }}>Padre</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={responsible.apoderado === 'dad'}
                                    onChange={() => setResponsible({ ...responsible, apoderado: 'dad' })}
                                />
                            }
                            label="Hacer Apoderado"
                            sx={{ color: '#555' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={responsible.economico === 'dad'}
                                    onChange={() => setResponsible({ ...responsible, economico: 'dad' })}
                                />
                            }
                            label="Responsable Económico"
                            sx={{ color: '#555' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" sx={{ color: '#06314e' }}>Madre</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={responsible.apoderado === 'mom'}
                                    onChange={() => setResponsible({ ...responsible, apoderado: 'mom' })}
                                />
                            }
                            label="Hacer Apoderado"
                            sx={{ color: '#555' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={responsible.economico === 'mom'}
                                    onChange={() => setResponsible({ ...responsible, economico: 'mom' })}
                                />
                            }
                            label="Responsable Económico"
                            sx={{ color: '#555' }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: '#f5f5f5', padding: '16px' }}>
                <Button onClick={() => onClose()} sx={{ color: '#f44336' }}>
                    Cancelar
                </Button>
                <Button
                    onClick={async () => {
                        if (isView) {
                            const res = await updateFamily(idFamily, { ...dataFamily, responsible });
                            if (res) {
                                onClose();
                                onCloseBack();
                                reloadData();
                                clearInputs();
                            } else {
                                Swal.fire({
                                    customClass: 'swal-overlay',
                                    title: 'Error',
                                    icon: 'error',
                                    text: "No se ha podido actualizar la familia. Inténtalo nuevamente."
                                });
                            }
                        } else {
                            const res = await createFamily({ ...dataFamily, responsible });
                            if (res) {
                                onClose();
                                onCloseBack();
                                reloadData();
                                clearInputs();
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    icon: 'error',
                                    text: "No se ha podido crear la familia. Inténtalo nuevamente."
                                });
                            }
                        }
                    }}
                    sx={{ backgroundColor: '#06314e', color: '#fff', '&:hover': { backgroundColor: '#045080' } }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
