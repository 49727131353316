import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc, writeBatch, setDoc, limit, deleteDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const createFamilyMulti = async (family) => {
    const batch = writeBatch(db);
    const childRefs = [];

    try {
        const familyRef = doc(collection(db, 'families'));
        await setDoc(familyRef, {
            dad: family.dad,
            payed: false,
            mom: family.mom,
            email: family.email,
            responsible: family.responsible,
            address: family.address,
            createdAt: serverTimestamp(),
        });

        for (const child of family.children) {
            const childRef = doc(collection(db, 'students'));
            childRefs.push(childRef.id);
            batch.set(childRef, {
                name: child.name,
                lastNameP: child.lastNameP,
                lastNameM: child.lastNameM,
                course: child.course,
                rut: child.rut,
                familyId: familyRef.id,
            });
        }

        await batch.commit();

        await updateDoc(familyRef, {
            children: childRefs,
        });

        return true;
    } catch (error) {
        console.error('Error creating family:', error);
        return false;
    }
};

export const createFamily = async (family) => {
    console.log(family);

    const childRefs = [];

    try {
        const familyRef = doc(collection(db, 'families'));
        await setDoc(familyRef, {
            dad: family.dad,
            payed: false,
            mom: family.mom,
            email: family.email,
            responsible: family.responsible,
            address: family.address,
            createdAt: serverTimestamp(),
        });

        for (const child of family.children) {
            const childRef = doc(collection(db, 'students'));
            childRefs.push({
                id: childRef.id,
                course: child.course
            });
            await setDoc(childRef, {
                name: child.name,
                lastNameP: child.lastNameP,
                lastNameM: child.lastNameM,
                course: child.course,
                enrollmentNumber: child.enrollmentNumber,
                rut: child.rut,
                familyId: familyRef.id,
            });
        }

        await updateDoc(familyRef, {
            children: childRefs,
        });

        return true;
    } catch (error) {
        console.error('Error creating family:', error);
        return false;
    }
};

export const updateFamily = async (familyId, updatedFamily) => {
    console.log(familyId);
    console.log(updatedFamily);


    try {
        const familyRef = doc(db, 'families', familyId);

        console.log(familyRef.id);


        const existingChildIds = updatedFamily.existingChildIds || [];

        console.log(existingChildIds);


        await updateDoc(familyRef, {
            dad: updatedFamily.dad,
            mom: updatedFamily.mom,
            email: updatedFamily.email,
            responsible: updatedFamily.responsible,
            address: updatedFamily.address,
            payed: updatedFamily.payed,
        });

        console.log("Termina de actualizar");


        const newChildIds = [];

        for (const child of updatedFamily.children) {
            console.log(child);

            if (child.id) {
                const childRef = doc(db, 'students', child.id);
                await updateDoc(childRef, {
                    name: child.name,
                    lastNameP: child.lastNameP,
                    lastNameM: child.lastNameM,
                    course: child.course,
                    rut: child.rut,
                });
                newChildIds.push(child.id);
                console.log("Si entra al if");

            } else {
                const newChildRef = doc(collection(db, 'students'));
                await setDoc(newChildRef, {
                    name: child.name,
                    lastNameP: child.lastNameP,
                    lastNameM: child.lastNameM,
                    course: child.course,
                    rut: child.rut,
                    familyId: familyId,
                });
                newChildIds.push(newChildRef.id);
            }
        }

        const childrenToRemove = existingChildIds.filter(id => !newChildIds.includes(id));
        for (const childId of childrenToRemove) {
            const childRef = doc(db, 'students', childId);
            await deleteDoc(childRef);
        }

        await updateDoc(familyRef, {
            children: newChildIds,
        });

        return true;
    } catch (error) {
        console.error('Error updating family:', error);
        return false;
    }
};

export const getFamilies = async (type, course) => {
    console.log("Buscando", type, course);

    try {
        const familiesCollection = collection(db, 'families');
        let q;

        if (type === 'all') {
            q = query(familiesCollection, orderBy('createdAt', 'desc'));
        } else if (type === 'debtors') {
            q = query(familiesCollection, where('payed', '==', false), orderBy('createdAt', 'desc'));
        } else {
            q = query(familiesCollection, where('payed', '==', true), orderBy('createdAt', 'desc'));
        }

        const querySnapshot = await getDocs(q);

        if (course == "all") {
            const families = querySnapshot.docs.map(doc => ({
                id: doc.id,
                alias: `${doc.get('dad.lastNameP')} ${doc.get('mom.lastNameP')}`,
                ...doc.data(),
            }));
            console.log(families);
            return families;
        } else {
            const families = querySnapshot.docs
                .map(doc => {
                    const familyData = doc.data();
                    const matchingChildren = familyData.children.filter(child => child.course === course);

                    if (matchingChildren.length > 0) {
                        return {
                            id: doc.id,
                            alias: `${familyData.dad.lastNameP} ${familyData.mom.lastNameP}`,
                            ...familyData,
                        };
                    }
                    return null;
                })
                .filter(family => family !== null);

            console.log(families);
            return families;
        }
    } catch (error) {
        console.error('Error fetching families:', error);
        console.log("Vacío");
        return [];
    }
};

export const getFamilyByRutRepresent = async (rutRepresent) => {
    console.log("Se busca", formatRUT(rutRepresent));

    try {
        const familiesCollection = collection(db, 'families');

        // Consultar por el rut del padre o de la madre
        const q1 = query(familiesCollection, where('dad.rut', '==', formatRUT(rutRepresent)));
        const q2 = query(familiesCollection, where('mom.rut', '==', formatRUT(rutRepresent)));

        const querySnapshotDad = await getDocs(q1);
        const querySnapshotMom = await getDocs(q2);

        console.log(querySnapshotDad.docs.length, 'del papi');

        let family = querySnapshotDad.docs.map(doc => ({
            id: doc.id,
            type: 'dad',
            ...doc.data(),
        }));


        if (family.length === 0) {
            console.log(querySnapshotMom.docs.length, 'de la mami');
            family = querySnapshotMom.docs.map(doc => ({
                id: doc.id,
                type: 'mom',
                ...doc.data(),
            }));
        }

        // Si no encontramos en los padres, consultar por los hijos
        if (family.length === 0) {
            const studentsCollection = collection(db, 'students');
            const q3 = query(studentsCollection, where('rut', '==', formatRUT(rutRepresent)));
            const querySnapshotChild = await getDocs(q3);

            console.log(querySnapshotChild.docs.length, ' aquiii');
            

            const children = querySnapshotChild.docs.map(doc => ({
                familyId: doc.data().familyId,
            }));

            if (children.length > 0) {
                const familyId = children[0].familyId;
                const familyDoc = await getDoc(doc(db, 'families', familyId));
                family = [{ id: familyDoc.id, ...familyDoc.data(), type: 'student', dataStudent:  querySnapshotChild.docs[0].data()}];
            }
        }

        console.log(family);
        

        if (family.length > 0) {
            if (family[0].payed === true) {
                return family[0];
            } else {
                return 'not payed';
            }
        } else {
            return false;
        }

    } catch (error) {
        console.error('Error fetching family by rut:', error);
        return null;
    }
};


export const getStudentsFromUnpaidFamilies = async () => {
    try {
        // Obtener las familias con estado de pago falso
        const familiesCollection = collection(db, 'families');
        const familiesQuery = query(familiesCollection, where('payed', '==', false));
        const familiesSnapshot = await getDocs(familiesQuery);

        const unpaidFamilyIds = familiesSnapshot.docs.map(doc => doc.id);
        const familiesData = familiesSnapshot.docs.map(doc => {
            return {
                name: doc.data().alias,
                id: doc.id,
                students: doc.data().children
            }
        });


        if (unpaidFamilyIds.length === 0) {
            return [];
        }

        // // Obtener los estudiantes que pertenecen a esas familias
        // const studentsCollection = collection(db, 'students');
        // const studentsQuery = query(studentsCollection, where('familyId', 'in', unpaidFamilyIds));
        // const studentsSnapshot = await getDocs(studentsQuery);

        // const students = studentsSnapshot.docs.map(doc => ({
        //     id: doc.id,
        //     ...doc.data(),
        // }));

        // const response = familiesSnapshot.docs.map(async doc => {
        //     return {
        //         name: doc.data().alias,
        //         id: doc.id,
        //         students: doc.data().children
        //     }
        // });

        return familiesData;
    } catch (error) {
        console.error('Error fetching students from unpaid families:', error);
        return [];
    }
};

export const getChildren = async (idFamily) => {
    console.log("Buscando hijos para el id de familia:", idFamily);
    try {
        const studentsCollection = collection(db, 'students');
        const q = query(
            studentsCollection,
            where('familyId', '==', idFamily)
        );
        const querySnapshot = await getDocs(q);

        console.log("Documentos encontrados:", querySnapshot.docs);

        const children = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return children;
    } catch (error) {
        console.error('Error al obtener los hijos:', error);
        return [];
    }
};
