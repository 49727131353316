import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { getPaysDataBingos } from '../database/bingos';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import ReusableDrawer from '../components/drawer';
import colors from '../utils/colors';
import { capitalizeWords } from '../utils/capitalize';
import loadingImg from '../assets/loading.svg'
import * as XLSX from 'xlsx';

function DashboardReporte() {
  const [data, setData] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState("init");
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(true);

  const totalEstudiantesPorCurso = [
    { curso: "Pre-Kinder-A", alumnos: 20 },
    { curso: "Pre-Kinder-B", alumnos: 18 },
    { curso: "Kinder-A", alumnos: 23 },
    { curso: "Kinder-B", alumnos: 23 },
    { curso: "Kinder-C", alumnos: 22 },
    { curso: "Primero-A", alumnos: 31 },
    { curso: "Primero-B", alumnos: 28 },
    { curso: "Primero-C", alumnos: 26 },
    { curso: "Segundo-A", alumnos: 32 },
    { curso: "Segundo-B", alumnos: 29 },
    { curso: "Segundo-C", alumnos: 28 },
    { curso: "Tercero-A", alumnos: 34 },
    { curso: "Tercero-B", alumnos: 33 },
    { curso: "Tercero-C", alumnos: 29 },
    { curso: "Cuarto-A", alumnos: 27 },
    { curso: "Cuarto-B", alumnos: 27 },
    { curso: "Cuarto-C", alumnos: 25 },
    { curso: "Quinto-A", alumnos: 33 },
    { curso: "Quinto-B", alumnos: 33 },
    { curso: "Sexto-A", alumnos: 33 },
    { curso: "Sexto-B", alumnos: 33 },
    { curso: "Séptimo-A", alumnos: 31 },
    { curso: "Séptimo-B", alumnos: 26 },
    { curso: "Séptimo-C", alumnos: 27 },
    { curso: "Octavo-A", alumnos: 32 },
    { curso: "Octavo-B", alumnos: 33 },
    { curso: "Primero-Medio-A", alumnos: 32 },
    { curso: "Primero-Medio-B", alumnos: 33 },
    { curso: "Segundo-Medio-A", alumnos: 30 },
    { curso: "Segundo-Medio-B", alumnos: 31 },
    { curso: "Tercero-Medio-A", alumnos: 32 },
    { curso: "Cuarto-Medio-A", alumnos: 33 },
    { curso: "Cuarto-Medio-B", alumnos: 22 }
  ];

  const calcularPagosRestantes = (pagosPorCurso, cursoSeleccionado) => {

    // Verificar si el curso seleccionado existe en los datos
    if (!pagosPorCurso[cursoSeleccionado]) {
      return `El curso ${cursoSeleccionado} no tiene datos registrados.`;
    }

    // Crear un conjunto de nombres únicos de estudiantes que han pagado en el curso seleccionado
    const estudiantesQueHanPagado = new Set(pagosPorCurso[cursoSeleccionado].map(estudiante => estudiante.nombre));


    const cursoData = totalEstudiantesPorCurso.find(curso => curso.curso === cursoSeleccionado);

    // Si el curso existe, toma el número de alumnos; de lo contrario, asigna 0
    const totalEstudiantes = cursoData ? cursoData.alumnos : 0;





    // Calcular los estudiantes que aún no han pagado
    const totalRestante = totalEstudiantes - estudiantesQueHanPagado.size;

    return totalRestante;
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPaysDataBingos();
      setData(result);
    };
    fetchData();
  }, []);

  if (!data) return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      justifyItems: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: '16%'
    }}>
      <img src={loadingImg} />
      <Typography>Cargando datos de reporte...</Typography>
    </Box>
  );

  // Datos para el gráfico de progreso global de ventas
  const totalVentas = data.montoTotalGeneral;
  // const cursoVentas = Object.keys(data.pagosPorCurso).map(curso => ({
  //   curso: curso.replaceAll("-", " "),
  //   ventas: data.pagosPorCurso[curso]
  // }));
  const cursoVentas = Object.keys(data.pagosPorCurso).map(curso => {
    // Sumar la cantidad de bingos por curso
    const totalVentas = data.pagosPorCurso[curso].reduce((acc, estudiante) => acc + estudiante.cantidad, 0);

    return {
      curso: curso.replaceAll("-", " "), // Reemplazar "-" con espacio para mejorar visualización
      ventas: totalVentas
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
          <p><strong>Curso:</strong> {label}</p>
          <p><strong>Ventas:</strong> {payload[0].value}</p>
        </div>
      );
    }
    return null;
  };


  // Datos específicos del curso seleccionado
  const estadisticasCurso = data.pagosPorCurso[selectedCourse] || 0;




  const handleDownloadReport = () => {
    const dataExcelCursos = [];
    const dataExcelGenerales = [];

    Object.keys(data.pagosPorCurso).forEach((curso, cursoIndex, cursosArray) => {
      data.pagosPorCurso[curso].forEach(estudiante => {
        dataExcelCursos.push({
          Curso: curso,  // Incluir siempre el nombre del curso
          Nombre: capitalizeWords(estudiante.nombre),
          Estado: estudiante.estado,
          'Cantidad de bingos': estudiante.cantidad,
          'Números de tarjetas': estudiante.idCards.join(', ')
        });
      });

      if (cursosArray[cursoIndex + 1] && cursosArray[cursoIndex + 1] !== curso) {
        dataExcelCursos.push({}); // Fila en blanco como separador
      }
    });



    data.generales.forEach(entrada => {
      const fechaPago = entrada.payedAt.toDate ? entrada.payedAt.toDate() : new Date(entrada.payedAt);
      const fechaFormateada = fechaPago.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
      dataExcelGenerales.push({
        Nombre: capitalizeWords(entrada.name + ' ' + entrada.lastName),
        'Fecha de pago': fechaFormateada,
        'Número tarjeta': entrada.idCard,
      });
    });

    const worksheetCursos = XLSX.utils.json_to_sheet(dataExcelCursos);
    const worksheetGenerales = XLSX.utils.json_to_sheet(dataExcelGenerales);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetCursos, 'Entradas de Curso');
    XLSX.utils.book_append_sheet(workbook, worksheetGenerales, 'Entradas Generales');

    XLSX.writeFile(workbook, 'La Salle - Reporte_detallado_bingos.xlsx');
  };


  const handleDownloadReportByCourse = (cursoSeleccionado) => {
    const dataExcel = [];

    // Filtrar y agregar los estudiantes del curso seleccionado
    if (data.pagosPorCurso[cursoSeleccionado]) {
      data.pagosPorCurso[cursoSeleccionado].forEach(estudiante => {
        dataExcel.push({
          Curso: cursoSeleccionado,
          Nombre: capitalizeWords(estudiante.nombre),
          Estado: estudiante.estado,
          'Cantidad de bingos': estudiante.cantidad,
          'Números de tarjetas': estudiante.idCards.join(', ')
        });
      });
    }

    const cursoLimpio = cursoSeleccionado.replace(/[:\\/?*[\]]/g, '');

    // Crear hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(dataExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Reporte ${cursoLimpio}`);

    // Descargar el archivo Excel
    XLSX.writeFile(workbook, `Reporte_${cursoLimpio}.xlsx`);
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      justifyItems: 'center',
      height: '100vh',
      width: '100vw',
      overflowX: 'hidden'
    }}>

      <Box padding={1} width="90%">
        <Typography variant="h5" color={colors.blue} align="center" gutterBottom>
          Resumen de Ventas de Bingo La Salle Temuco 2024
        </Typography>

        <Paper elevation={0} sx={{ backgroundColor: '#003D5517', padding: 3, marginBottom: 3, borderRadius: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">Progreso global de ventas</Typography>
            <Button variant="contained" onClick={handleDownloadReport}>Descargar reporte detallado</Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">
              Entradas vendidas de cursos: {cursoVentas.reduce((acc, curso) => acc + curso.ventas, 0)}
            </Typography>
            <Typography variant="body1">
              Entradas generales vendidas: {data.entradasGenerales}
            </Typography>
          </Box>



          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={cursoVentas}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="curso" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="ventas" fill="#CD9E01">
                <LabelList dataKey="ventas" position="inside" fill="#FFFFFF" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Paper>

        <Box marginBottom={3}>
          <FormControl fullWidth sx={{ borderRadius: '8px' }}>
            <Select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              label="Selecciona un curso"
              variant="filled"
              disableUnderline
              sx={{
                height: '100%',
                bgcolor: colors.blue,
                borderRadius: '8px',
                '& .MuiSelect-select': {
                  padding: '10px',
                  '&:hover': {
                    height: '100%',
                    borderRadius: '10px',
                    bgcolor: colors.blue
                  },
                },
                '&.Mui-focused': {
                  bgcolor: colors.blue
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFilledInput-root': {
                  backgroundColor: colors.blue,
                },
                color: 'white'
              }}
            >
              <MenuItem value="init">Selecciona un curso</MenuItem>
              {Object.keys(data.pagosPorCurso).map((curso, index) => (
                <MenuItem key={index} value={curso}>{curso.replaceAll("-", " ")}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>


        {
          selectedCourse && selectedCourse !== 'init' && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ padding: 3, backgroundColor: '#003D5517', borderRadius: '10px' }}>
                  <Typography variant="h6">Estadísticas de {selectedCourse.replaceAll("-", " ")}</Typography>


                  <Typography>Bingos vendidos: {data.pagosPorCurso[selectedCourse]?.reduce((acc, estudiante) => acc + 1, 0) || 0}</Typography>
                  <Typography>Bingos extra vendidos: {data.pagosPorCurso[selectedCourse]?.reduce((acc, estudiante) => acc + (estudiante.cantidad > 1 ? estudiante.cantidad - 1 : 0), 0) || 0}</Typography>


                  <Typography>Monto Recaudado: ${data.montoRecaudadoPorCurso[selectedCourse] || 0}</Typography>

                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart
                      data={[
                        {
                          name: 'Bingos', value: data.pagosPorCurso[selectedCourse]?.reduce((acc, estudiante) => {
                            return acc + (estudiante.cantidad > 0 ? 1 : 0);
                          }, 0) || 0
                        },
                        { name: 'Extra', value: data.pagosPorCurso[selectedCourse]?.reduce((acc, estudiante) => acc + (estudiante.cantidad > 1 ? estudiante.cantidad - 1 : 0), 0) || 0 },
                        { name: 'No pagado', value: calcularPagosRestantes(data.pagosPorCurso, selectedCourse) }
                      ]}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#003D55">
                        <LabelList dataKey="value" position="inside" fill="#FFFFFF" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ padding: 3, maxHeight: '400px', overflowY: 'auto', border: '1px solid grey', borderRadius: '10px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Lista de estudiantes</Typography>
                    <Button variant="contained" onClick={() => {
                      handleDownloadReportByCourse(selectedCourse)
                    }}>Descargar reporte de curso</Button>
                  </Box>
                  <TableContainer sx={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' }
                  }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Estado</TableCell>
                          <TableCell>Cantidad</TableCell>
                          <TableCell>Extra</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.pagosPorCurso[selectedCourse]?.map((estudiante, index) => (
                          <TableRow key={`${selectedCourse}-${index}`}>
                            <TableCell>{capitalizeWords(estudiante.nombre)}</TableCell>
                            <TableCell><Box sx={{ color: 'green' }}>{estudiante.estado}</Box></TableCell>
                            <TableCell>{estudiante.cantidad}</TableCell>
                            <TableCell sx={{
                              fontWeight: 'bold',
                              color: (estudiante.cantidad - 1) > 0 ? 'green' : 'grey'
                            }}>{(estudiante.cantidad - 1) > 0 ? `+${estudiante.cantidad - 1}` : '-'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          )
        }

      </Box>
    </div>
  );
}


export default DashboardReporte;
