import { addDoc, collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from './config_firebase';
import * as XLSX from 'xlsx';

export const getBingoStudents = async () => {
    try {
        const studentsCollection = collection(db, 'bingo_students');

        const studentsQuery = query(studentsCollection, orderBy('boleto'));

        const querySnapshot = await getDocs(studentsQuery);

        const students = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return students;
    } catch (error) {
        console.error('Error fetching students:', error);
        return [];
    }
};

export const verifyStudent = async (rut) => {
    try {
        const studentsRef = collection(db, 'alumnos_matriculas');
        const rutQuery = query(studentsRef, where('rut', '==', rut));
        const rutSnapshot = await getDocs(rutQuery);

        if (rutSnapshot.empty) {
            console.log('Estudiante no encontrado');
            return [];
        }

        const studentDoc = rutSnapshot.docs[0];
        const studentData = studentDoc.data();
        const codigoFamilia = studentData.codigoFamilia;

        const familyQuery = query(studentsRef, where('codigoFamilia', '==', codigoFamilia));
        const familySnapshot = await getDocs(familyQuery);

        const familyMembers = familySnapshot.docs.map(doc => ({
            name: doc.data().nombre,
            course: doc.data().curso,
            rut: doc.data().rut,
            isComplete: true
        }));

        return familyMembers;

    } catch (error) {
        console.error('Error buscando estudiantes:', error);
        return [];
    }
};

export const getStudents = async () => {
    try {
        const studentsCollection = collection(db, 'students');
        const studentsQuery = query(studentsCollection, orderBy('name'));
        const querySnapshot = await getDocs(studentsQuery);

        const students = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        const familyIds = students
            .filter(student => student.familyId)
            .map(student => student.familyId);

        let families = [];
        if (familyIds.length > 0) {
            const familiesCollection = collection(db, 'families');
            const familiesQuery = query(familiesCollection, where('__name__', 'in', familyIds));
            const familiesSnapshot = await getDocs(familiesQuery);

            families = familiesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
        }

        const studentsWithFamilies = students.map(student => {
            const family = families.find(family => family.id === student.familyId);
            return {
                ...student,
                family: family || null,
            };
        });

        return studentsWithFamilies;
    } catch (error) {
        console.error('Error fetching students:', error);
        return [];
    }
};

export const convertirCurso = (curso) => {
    const regexBasico = /^(\d)°([A-C])$/; 
    const regexMedio = /^(I{1,3}|IV)°([A-C])$/;
    const regexKinder = /^(K|PK)-([A-C])$/; 

    if (regexBasico.test(curso)) {
        const [, numero, seccion] = curso.match(regexBasico);
        const grados = ["Primero", "Segundo", "Tercero", "Cuarto", "Quinto", "Sexto", "Séptimo", "Octavo"];
        return `${grados[parseInt(numero) - 1]}-${seccion}`;
    } else if (regexMedio.test(curso)) {
        const [, nivel, seccion] = curso.match(regexMedio);
        const gradosMedio = {
            "I": "Primero-Medio",
            "II": "Segundo-Medio",
            "III": "Tercero-Medio",
            "IV": "Cuarto-Medio"
        };
        return `${gradosMedio[nivel]}-${seccion}`;
    } else if (regexKinder.test(curso)) {
        const [, tipo, seccion] = curso.match(regexKinder);
        const gradosKinder = {
            "K": "Kinder",
            "PK": "Pre-Kinder"
        };
        return `${gradosKinder[tipo]}-${seccion}`;
    }

    return curso;
};



export async function uploadStudents(file) {
    try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(sheet);

        rows.forEach(async (row) => {
            const [apellido, nombre] = row["Nombre"].split(", ");
            const curso = convertirCurso(row["Curso"]);
            const boleto = row["Boleto"];

            try {
                await addDoc(collection(db, "bingo_students"), {
                    nombre: nombre.trim(),
                    apellido: apellido.trim(),
                    curso: curso,
                    boleto: boleto
                });
                console.log(`Alumno ${nombre} ${apellido} añadido con éxito.`);
            } catch (e) {
                console.error("Error al añadir alumno: ", e);
            }
        });
        return true;
    } catch (error) {
        console.log("Error");
        return false;
    }
}