export function capitalize(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const capitalizeWords = (str) => {
    if (typeof str !== 'string') return '';
    return str.toLowerCase().replace(/(^|\s|\.|-)\S/g, char => char.toUpperCase());
};
export const formatBoleto = (boleto) => {
    return boleto.toString().padStart(5, '0');
};