import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import sponsor1 from "../assets/gifu6L2fYzx2bKDMxcz8BLBl0FZAw3.png"
import sponsor2 from "../assets/olsT8FesV8ZRtNCrGe5DVugMSoI593.png"
import sponsor3 from "../assets/aMLJ54g4vQY77HvXMlwIlAOt0iyhEW.png"
import sponsor4 from "../assets/cM5kpHNQ33TbQ0PWA9WZS1NgxufolG.png"
import sponsor5 from "../assets/9qrUzlZ37IOuQvCvhFbUj1aP2Lr8H0.png"
import sponsor6 from "../assets/cNYkgR3kmfqXEagLdf7XmXITkhlQTb.png"
import sponsor7 from "../assets/2GmuiCsLJ5ymr4ec2fgXDqxGafqRHI.png"
import sponsor8 from "../assets/logo8.png"
import sponsor9 from "../assets/logo9.png"
import jsPDF from 'jspdf';
import logoBlue from '../assets/salle_blue_text.png'
import QRCode from 'qrcode';
import CryptoJS from 'crypto-js';



const BingoPdfModal = ({ open, onClose }) => {
    const [bingoCode, setBingoCode] = useState('');

    const handleInputChange = (e) => {
        setBingoCode(e.target.value);
    };

    const handlePdfGeneration = () => {
        if (bingoCode.trim()) {
            generatePDFWithQR();
            setBingoCode('')
            onClose();
        } else {
            alert("Por favor, ingrese un código de bingo válido.");
        }
    };

    const generateIdEncrypted2 = (id) => {
        let res = CryptoJS.AES.encrypt(id, process.env.REACT_APP_ENCRYPT_KEY).toString()
        return res
    }


    const generatePDFWithQR = async () => {
        const qrBase64 = await QRCode.toDataURL(`https://salle-temuco.maxipos.cl/viewBingo?id=${generateIdEncrypted2(bingoCode)}`, { type: 'image/png' });
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [480, 300]
        });

        const leftSectionWidth = 30;
        doc.setFillColor(223, 242, 255);
        doc.rect(0, 0, leftSectionWidth, 300, 'F');

        const textX = leftSectionWidth / 2;
        const textY = (300 / 2) + (doc.getTextWidth(`NUMERO: ${bingoCode}`) / 2);
        doc.setFontSize(14);
        doc.setTextColor(0, 51, 85);

        doc.text(`NÚMERO: ${bingoCode}`, textX, textY, { angle: 90 });
        const middleSectionWidth = 350;
        const middleSectionX = leftSectionWidth;
        doc.setFillColor(255, 255, 255);
        doc.rect(middleSectionX, 0, middleSectionWidth, 300, 'F');


        doc.setFontSize(28);
        doc.setTextColor(0, 51, 85);
        doc.text('Gran Bingo 2024', middleSectionX + (middleSectionWidth / 2) - (doc.getTextWidth('Gran Bingo 2024') / 2), 80);

        doc.addImage(logoBlue, 'PNG', middleSectionX + (middleSectionWidth / 2) - 50, 100, 100, 35);

        const boxWidth = 170;
        const boxX = middleSectionX + (middleSectionWidth / 2) - (boxWidth / 2);
        const boxY = 150;

        doc.setFontSize(14);
        doc.setTextColor(255, 255, 255);
        doc.setFillColor(0, 51, 85);
        doc.rect(boxX, boxY, boxWidth, 45, 'F');

        const firstText = 'SÁBADO 16 DE NOVIEMBRE';
        const firstTextWidth = doc.getTextWidth(firstText);
        doc.text(firstText, boxX + (boxWidth / 2) - (firstTextWidth / 2), boxY + 20);


        const secondText = '15:00 HRS';
        const secondTextWidth = doc.getTextWidth(secondText);
        doc.text(secondText, boxX + (boxWidth / 2) - (secondTextWidth / 2), boxY + 35);

        const baseY = 230;
        const rowHeight = 30;


        doc.setFontSize(10);
        doc.setTextColor(0, 51, 85);
        doc.text('AGRADECEMOS LA COLABORACIÓN DE', middleSectionX + (middleSectionWidth / 2) - (doc.getTextWidth('AGRADECEMOS LA COLABORACIÓN DE') / 2), 210);

        const sponsors = [
            { url: sponsor1, width: 40, height: 15 },
            { url: sponsor2, width: 30, height: 30 },
            { url: sponsor3, width: 30, height: 30 },
            { url: sponsor4, width: 30, height: 30 },
            { url: sponsor5, width: 30, height: 30 },
            { url: sponsor6, width: 30, height: 30 },
            { url: sponsor7, width: 30, height: 30 },
            { url: sponsor8, width: 30, height: 30 },
            { url: sponsor9, width: 30, height: 30 },

        ];


        let totalWidth = sponsors.reduce((acc, sponsor) => acc + sponsor.width, 0) + (sponsors.length - 1) * 10;


        let sponsorX = middleSectionX + (middleSectionWidth / 2) - (totalWidth / 2);


        sponsors.forEach((sponsor) => {

            let sponsorY = baseY + (rowHeight - sponsor.height) / 2;
            doc.addImage(sponsor.url, 'PNG', sponsorX, sponsorY, sponsor.width, sponsor.height);
            sponsorX += sponsor.width + 10;
        });

        const rightSectionWidth = 100;
        doc.setFillColor(223, 242, 255);
        doc.rect(480 - rightSectionWidth, 0, rightSectionWidth, 300, 'F');

        const qrSize = 80;
        const qrXPosition = 480 - rightSectionWidth + (rightSectionWidth - qrSize) / 2;
        doc.addImage(qrBase64, 'PNG', qrXPosition, 100, qrSize, qrSize);


        doc.save(`Bingo_${bingoCode}.pdf`);
        
    };


    return (
        <Modal open={open} onClose={onClose} aria-labelledby="bingo-modal-title" aria-describedby="bingo-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                }}
            >
                <Typography id="bingo-modal-title" variant="h6" component="h2" color="#06314E">
                    Generar PDF de Bingo
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Ingrese código de bingo"
                    value={bingoCode}
                    onChange={handleInputChange}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            '& fieldset': { borderColor: '#ccc' },
                        },
                    }}
                />
                <Button
                    onClick={handlePdfGeneration}
                    variant="contained"
                    sx={{
                        bgcolor: '#06314E',
                        ':hover': { bgcolor: '#052F3B' },
                    }}
                >
                    Generar PDF
                </Button>
            </Box>
        </Modal>
    );

};



export default BingoPdfModal;