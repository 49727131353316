import './App.css';
import Login from './pages/login';
import Home from './pages/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Families from './pages/families';
import Students from './pages/students';
import PendingPays from './pages/pending_pays';
import SearchPage from './pages/public_pay';
import QrPage from './pages/qr_page';
import GenerateQr from './pages/create_qr';
import Settings from './pages/settings';
import BingoHome from './pages/bingo_home';
import ViewBingo from './pages/view_bingo';
import BingoReports from './pages/bingo_reports';
// import UploadStudents from './pages/upload_students';
// import UploadStudentMatriculas from './pages/upload_students_matricula';

function App() {

  const { logged, role } = useSelector((state) => ({
    logged: state.auth.logged,
    role: state.auth.userData?.role || ""
  }));


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={logged && role === 'admin' ? <Home /> : <Login />} />
        <Route path="/home" element={logged && role === 'admin' ? <Home /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/families" element={<Families />} />
        <Route path="/students" element={<Students />} />
        <Route path="/pendingPays" element={<PendingPays />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/pay" element={<SearchPage />} />
        <Route path="/bingo" element={<BingoHome />} />
        <Route path="/bingoReports" element={logged ? <BingoReports /> : <Login />} />
        <Route path="/viewBingo" element={<ViewBingo />} />
        <Route path="/qr" element={<QrPage />} />
        <Route path="/getMyQr" element={<GenerateQr />} />
        {/* <Route path="/uploadStudents" element={<UploadStudents />} /> */}
        {/* <Route path="/uploadStudentsMatricula" element={<UploadStudentMatriculas />} /> */}


      </Routes>
    </BrowserRouter>
  );
}

export default App;

