import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import AddFamilyModal from '../components/family_modal';
import { getFamilies } from '../database/family';
import loadingImg from '../assets/loading.svg';
import { Box, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Pagination, Fab, TextField, InputAdornment, Avatar, FormControl, InputLabel, Select, MenuItem, Typography, Button } from '@mui/material';
import colors from '../utils/colors';
import { ArrowForwardIos, Search, Add, Mail, ForwardToInbox } from '@mui/icons-material';
import { capitalize, capitalizeWords } from '../utils/capitalize';
import Swal from 'sweetalert2';
import axios from 'axios';
import { getAllCourses } from '../database/courses';

export default function Families() {
    const [modalOpen, setModalOpen] = useState(false);
    const [families, setFamilies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFamilies, setFilteredFamilies] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState({});
    const [selectedType, setSelectedType] = useState("all");
    const [types, setTypes] = useState([
        {
            value: "debtors",
            label: 'Con deudas'
        },
        {
            value: "Sin deudas",
            label: "Sin deudas"
        }
    ])

    const handleChangeType = (val) => {
        setSelectedType(val.target.value)
    }

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    const debtors = families.filter(family => !family.payed);

    const prepareEmailData = () => {
        return debtors.map(family => ({
            name: family.responsible === 'dad' ? family.dad.name : family.mom.name,
            email: family.email,
            rut: family.responsible === 'dad' ? family.dad.rut : family.mom.rut
        }));
    };

    const sendReminders = async (emails) => {
        const result = await Swal.fire({
            title: 'Enviar Recordatorio de pago',
            text: '¿Estás seguro de que deseas enviar un recordatorio de pago a las familias que estén pendientes en el curso seleccionado?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            const response = await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendMultiReminder', {
                paymentLink: `https://salle.maxipos.cl/pay`,
                emails: emails,
                subject: "Recordatorio de pago"
            })
            if (response.status === 200) {
                Swal.fire(
                    'Enviado!',
                    'Los recordatorios de pago han sido enviado.',
                    'success'
                );
            } else {
                Swal.fire(
                    'Error',
                    'El recordatorio no ha podido enviarse. Revisa el correo del apoderado.',
                    'error'
                );
            }

        }
    }

    const [courseOptions, setCourseOptions] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('all');

    const [isView, setIsView] = useState(false)

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedFamilies = filteredFamilies.slice(startIndex, endIndex);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    async function getRequests() {
        setLoading(true);
        try {
            const response = await getFamilies(selectedType, selectedCourse);
            const coursesRes = await getAllCourses();
            let itemcourses = coursesRes.map((el) => ({
                value: el.id,
                label: el.name
            }));
            setCourseOptions(itemcourses)
            setFamilies(response);
        } catch (error) {
            console.error('Error fetching families:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getRequests();
    }, [selectedType, selectedCourse]);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = families.filter(family =>
            family.alias && family.alias.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredFamilies(filteredData);
        setPage(1);
    }, [searchTerm, families]);

    const handleOpen = () => {
        setIsView(false)
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const [drawerOpen, setDrawerOpen] = useState(true);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const sendMailReminder = async (rut, name, email) => {
        const result = await Swal.fire({
            title: 'Enviar Recordatorio de pago',
            text: '¿Estás seguro de que deseas enviar un recordatorio de pago al responsable económico de la familia?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            const response = await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendPaymentReminderEmail', {
                name: name,
                paymentLink: `https://salle.maxipos.cl/pay?rut=${rut}`,
                // paymentLink: `http://localhost:3000/pay?rut=${rut}`,
                email: "javitoxd00@gmail.com",
                subject: "Recordatorio de pago"
            })
            if (response.status === 200) {
                Swal.fire(
                    'Enviado!',
                    'El recordatorio de pago ha sido enviado.',
                    'success'
                );
            } else {
                Swal.fire(
                    'Error',
                    'El recordatorio no ha podido enviarse. Revisa el correo del apoderado.',
                    'error'
                );
            }

        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'families'} />
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
                    <p>Cargando familias...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <ListItem sx={{ paddingX: 5 }}>
                        <ListItemText sx={{ color: colors.green }} primary={`Todas las familias (${families.length} encontrada(s))`}
                            primaryTypographyProps={{
                                fontFamily: 'Nunito',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                color: colors.blue,
                                textAlign: 'center'
                            }} />
                    </ListItem>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', width: '90%', mb: 2 }}
                    >
                        <TextField
                            placeholder="Buscar familia por apellido"
                            variant='outlined'
                            sx={{
                                mr: 1,
                                width: '90%',
                                backgroundColor: colors.greyBack,
                                borderRadius: '50px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '50px'
                                    },
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <FormControl sx={{ width: '28%' }}>
                            <InputLabel>Selecciona un curso</InputLabel>
                            <Select
                                value={selectedCourse}
                                onChange={handleCourseChange}
                                variant="outlined"
                                label="Selecciona un curso"
                                sx={{
                                    mr: 1,
                                    backgroundColor: colors.greyBack,
                                    borderRadius: '50px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="all">
                                    <em>Todos los cursos</em>
                                </MenuItem>
                                {courseOptions.map((course) => (
                                    <MenuItem key={course.value} value={course.value}>
                                        {course.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '28%' }}>
                            <InputLabel>Selecciona el tipo</InputLabel>
                            <Select
                                value={selectedType}
                                onChange={handleChangeType}
                                variant="outlined"
                                label="Selecciona un curso"
                                sx={{
                                    backgroundColor: colors.greyBack,
                                    borderRadius: '50px',
                                    // '& .MuiOutlinedInput-root': {
                                    //     '& fieldset': {
                                    //         border: 'none',
                                    //     },
                                    // },
                                }}
                            >
                                <MenuItem value="all">
                                    <em>Todas las familias</em>
                                </MenuItem>
                                {types.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{
                        mb: 2,
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="h6" >
                            Familias pagadas: {families.filter(family => family.payed).length} de {families.length}
                        </Typography>
                        <Button
                            onClick={() => {
                                const emails = prepareEmailData();
                                console.log(emails);
                                sendReminders(emails)
                            }}
                            sx={{
                                backgroundColor: 'orange',
                                color: 'white'
                            }}>
                            Enviar recordatorios de pago
                        </Button>
                    </Box>
                    {paginatedFamilies.map((family) => (
                        <ListItem
                            onClick={() => {
                                setIsView(true)
                                setSelectedFamily(family);
                                setModalOpen(true)
                            }}
                            key={family.id} sx={{
                                cursor: 'pointer',
                                margin: '2px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '10px',
                                border: '1px solid #1212121F',
                            }}>
                            <ListItemAvatar>
                                <Avatar sx={{ backgroundColor: colors.blue }}>
                                    {family.alias ? capitalize(family.alias).substring(0, 1) : ''}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ ml: 1 }}
                                primaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                secondaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                primary={`Familia ${capitalizeWords(family.alias)}` || 'Sin alias'}
                                secondary={`${family.children.length} hijo${family.children.length > 1 ? 's' : ''} - ${capitalizeWords(family.address)}`}
                            />
                            {
                                family.payed ? (

                                    <ListItemIcon>
                                        <ArrowForwardIos sx={{
                                            fontSize: '40px'
                                        }} />
                                    </ListItemIcon>

                                ) : (
                                    <ListItemIcon
                                        onClick={
                                            (e) => {
                                                setSelectedFamily(family);
                                                console.log(family);
                                                e.preventDefault()
                                                e.stopPropagation();
                                                if (family.responsible.economico === 'dad') {
                                                    sendMailReminder(family.dad.rut, `${family.dad.name} ${family.dad.lastNameP}`, family.email);
                                                } else {
                                                    sendMailReminder(family.mom.rut, `${family.mom.name} ${family.mom.lastNameP}`, family.email);
                                                }
                                            }}>
                                        <ForwardToInbox sx={{
                                            fontSize: '40px'
                                        }} />
                                    </ListItemIcon>
                                )
                            }
                        </ListItem>
                    ))}
                    <Pagination
                        count={Math.ceil(filteredFamilies.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        sx={{ mt: 2 }}
                    />
                </Box>
            )
            }
            <Fab
                color="primary"
                aria-label="add"
                onClick={handleOpen}
                sx={{ position: 'fixed', bottom: 16, right: 16, borderRadius: '10px' }}
            >
                <Add />
            </Fab>
            {
                modalOpen && (
                    <AddFamilyModal open={modalOpen} handleClose={() => {
                        setSelectedFamily({})
                        handleClose();

                    }} reloadData={getRequests} isView={isView} data={selectedFamily} />
                )
            }
        </div >
    );



}
