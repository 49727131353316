import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GiftIcon from '@mui/icons-material/CardGiftcard';


const Rewards = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (

        <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
                backgroundColor: '#004b71',
                borderRadius: '10px',
                color: 'white',
                boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                '&:before': { display: 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                sx={{
                    backgroundColor: '#006b8f',
                    borderRadius: '10px 10px 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                }}
            >
                <GiftIcon sx={{ marginRight: '10px', color: 'orange' }} />
                <Typography sx={{ fontWeight: 'bold' }}>Premios</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: '#006b8f',
                    borderRadius: '0 0 10px 10px',
                    padding: '15px',
                }}
            >
                <Box>
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '10px' }}>Premios del Bingo:</Typography>
                    <Typography>
                        <strong>1.- $1.000.000.</strong> (Donado por CGPA)<br />
                        <strong>2.- Estadía Isla de Pascua 2 personas, 4 Noches- 5 días.</strong> (Donado por Easter Island, I° Medio B)<br />
                        <strong>3.- $200.000.</strong> (Donado por IV° Medio A y B)<br />
                        <strong>4.- Estadía Bariloche 2 personas, 3 Noches – 4 días.</strong> (Donado por Estugira, IV° Medio A)<br />
                        <strong>5.- Gift Card $100.000.- Servicio Salón Loreto Rosemberg.</strong> (Donado por IV° Medio B)<br />
                        <strong>6.- Estadía Bariloche 2 personas, 3 Noches- 4 días.</strong> (Donado por American Travel, IV° Medio B)<br />
                        <strong>7.- $200.000.</strong> (Donado por Generación 2023, IV° medio A y B y II medio A)<br />
                        <strong>8.- Ternero 7 meses.</strong> (Donado por 8° A)<br />



                    </Typography>

                    <Typography sx={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '10px' }}>Premios del Binguito:</Typography>
                    <Typography>
                        <strong>1.- 1 año academia Arte Movimiento.</strong> (Donado por CGPA)<br />
                        <strong>2.- Set audífonos Gamer + teclado. </strong><br />
                        <strong>3.- $50.000.</strong> (Donado por II Medio B)<br />
                        <strong>4.- Once alemana para 2 personas.</strong> (Donado por Cafetería Apfelnuss)<br />
                        <strong>5.- Scooter eléctrico  y set Mochila outdoor, marca Totto.</strong> (Donado por Universidad Autónoma, III° Medio)<br />
                        <strong>6.- 2 pares de zapatos a elección.</strong> (Donado por Ignacia Zapatos)<br />
                        <strong>7.- Horno eléctrico.</strong> (Donado por Maxipos)<br />
                        <strong>8.- 1 Torta para 20 personas (Tortas del Sendero) + 1 cafetera + 1 café de grano.</strong>

                    </Typography>
                </Box>
            </AccordionDetails>
        </Accordion>

    );
};

export default Rewards;
