import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Stepper, Step, StepLabel, Box, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, useMediaQuery, Divider, Grid, Tooltip, Popover, InputAdornment } from '@mui/material';
import formatRUT from '../utils/format_rut';
import Swal from 'sweetalert2';
import loadingImg from '../assets/loading.svg'
import getSignature from '../utils/get_signature';
import { generateRandomString } from '../utils/generate_string';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkBoletoExists, createNewBingo, getBingoStatus } from '../database/bingos';
import Confetti from 'react-confetti';
import colors from '../utils/colors';
import logoBingo from '../assets/logo-bingo.svg'
import { ArrowForwardIos, CheckCircle, CheckCircleOutline, Delete, DeleteOutline, QuestionAnswer, QuestionMark } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import axios from 'axios';
import { capitalize, capitalizeWords, formatBoleto } from '../utils/capitalize';
import QRCode from 'qrcode';
import CryptoJS from 'crypto-js';
import html2canvas from 'html2canvas';
import BoletoComponent from '../components/boleto';
import jsPDF from 'jspdf';
import logoBlue from '../assets/salle_blue_text.png'

import sponsor1 from "../assets/gifu6L2fYzx2bKDMxcz8BLBl0FZAw3.png"
import sponsor2 from "../assets/olsT8FesV8ZRtNCrGe5DVugMSoI593.png"
import sponsor3 from "../assets/aMLJ54g4vQY77HvXMlwIlAOt0iyhEW.png"
import sponsor4 from "../assets/cM5kpHNQ33TbQ0PWA9WZS1NgxufolG.png"
import sponsor5 from "../assets/9qrUzlZ37IOuQvCvhFbUj1aP2Lr8H0.png"
import sponsor6 from "../assets/cNYkgR3kmfqXEagLdf7XmXITkhlQTb.png"
import sponsor7 from "../assets/2GmuiCsLJ5ymr4ec2fgXDqxGafqRHI.png"
import sponsor8 from "../assets/logo8.png"
import sponsor9 from "../assets/logo9.png"

import Rewards from '../components/rewards';
import YouTubeDialog from '../components/modal_video';
import { getBingoStudents } from '../database/students';

function BingoHome() {
    const [activeStep, setActiveStep] = useState(0);
    const [openModal1, setOpenModal1] = useState(true)
    const steps = ['Selecciona tu entrada', 'Formulario', 'Verificación y pago', 'Comprobante'];
    const [type, setType] = useState(null)
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [hasLoaded, setHasLoaded] = useState(false);
    const [students, setStudents] = useState([{ idCard: '', nameStudent: '', course: '', isComplete: false }]);
    const [bingoStudents, setBingoStudents] = useState([])
    const [studentsInCourse, setStudentsInCourse] = useState([])

    const [isRendered, setIsRendered] = useState(false);

    const handleAddStudent = (index) => {
        const updatedStudents = [...students];
        const currentStudent = updatedStudents[index];

        if (currentStudent.course && currentStudent.selectedStudent && currentStudent.idCard) {
            currentStudent.isComplete = true;
            setStudents(updatedStudents);
            setStudents([...students,
            { idCard: '', nameStudent: '', course: '', isComplete: false }
            ])
            setStudentsInCourse([])
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Completar datos',
                text: "Para agregar un nuevo bingo debes completar toda la información del bingo actual."
            })
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    let sponsors = [sponsor1, sponsor2, sponsor3, sponsor4, sponsor5, sponsor6, sponsor7, sponsor8, sponsor9]

    const qrRef = useRef();
    const qrRefs = useRef([]);

    const [value, setValue] = useState(10000) // 10000)
    const location = useLocation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [email, setEmail] = useState(null)
    const [name, setName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [emailConfirm, setEmailConfirm] = useState("")
    const [amount, setAmount] = useState('');
    const [genIdCard, setGenIdCard] = useState()



    const handleStudentSelectChange = async (index, selectedStudentId) => {
        Swal.fire({
            customClass: {
                popup: 'swal-overlay'
            },
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const student2 = studentsInCourse.find(student => student.id === selectedStudentId);
        const formattedBoleto = formatBoleto(student2.boleto)

        const exists = await checkBoletoExists(formattedBoleto);
        if (exists) {
            handleStudentChange(index, 'selectedStudent', selectedStudentId);
            handleStudentChange(index, 'nameStudent', `${capitalizeWords(student2.nombre)} ${capitalizeWords(student2.apellido)}`);
            handleStudentChange(index, 'enableEdit', true);
            Swal.fire({
                icon: 'success',
                text: "El bingo obligatorio del estudiante ya se encuentra pagado, si deseas comprar uno extra puedes ingresar el N° de la tarjeta",
                title: 'Ya pagado'
            })
        } else if (students.find(student => student.idCard === formatBoleto(student2.boleto))) {
            handleStudentChange(index, 'enableEdit', true);
            handleStudentChange(index, 'selectedStudent', selectedStudentId);
            handleStudentChange(index, 'nameStudent', `${capitalizeWords(student2.nombre)} ${capitalizeWords(student2.apellido)}`);
            Swal.fire({
                icon: 'warning',
                text: "Como ya tienes en tu carrito el bingo obligatorio, a continuación puedes comprar un bingo extra, indicando el N° de la tarjeta de bingo. (Ejemplo: 00001)",
                title: 'Comprar bingo extra'
            })
        } else {
            handleStudentChange(index, 'selectedStudent', selectedStudentId);
            handleStudentChange(index, 'nameStudent', `${capitalizeWords(student2.nombre)} ${capitalizeWords(student2.apellido)}`);
            handleStudentChange(index, 'idCard', formattedBoleto);
            Swal.close();

        }


    };

    // const handleChangeIdCardStudent

    useEffect(() => {
        const executeAsyncTask = async () => {
            console.log("Se llama la función");
            if (hasLoaded) return;

            console.log("Ahora comienza");

            const params = new URLSearchParams(location.search);

            if (!params.toString()) {
                console.log('No se encontraron parámetros en la URL.');
                return;
            }

            Swal.fire({
                customClass: {
                    popup: 'swal-overlay'
                },
                imageUrl: loadingImg,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            console.log("PArametroooooos", params);


            // Obtener los parámetros de la URL
            let res = params.get('res');
            let idCard = params.get('idCard');
            let nameParams = params.get('name');
            let lastNameParams = params.get('lastName')
            let emailParams = params.get('email');
            let amountParams = params.get('x_amount');
            let referenceParams = params.get('x_reference');
            let resultParams = params.get('x_result');
            let timestampParams = params.get('x_timestamp');
            let messageParams = params.get('x_message');
            let signatureParams = params.get('x_signature');

            setAmount(amountParams)
            setName(`${nameParams} ${lastNameParams}`)
            setEmail(emailParams)
            setGenIdCard(idCard)

            const studentsParam = params.get('students');
            let studentsParams;
            if (studentsParam) {
                studentsParams = JSON.parse(decodeURIComponent(studentsParam));
            }

            console.log("Parámetros de la URL", {
                res, idCard, nameParams, emailParams, amountParams, referenceParams, resultParams, timestampParams, signatureParams, studentsParams
            });

            setStudents(studentsParams)


            if (res === 'completed' && emailParams && nameParams && amountParams && referenceParams && resultParams && timestampParams && signatureParams) {
                setOpenModal1(false)

                if (idCard && !studentsParams) {
                    console.log("Caso de pago general con ID:", idCard);

                    let res = await getBingoStatus(idCard);
                    if (res === 'ya existe') {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ha ocurrido un error',
                            text: 'La entrada ya se encuentra pagada. Si tu caso no ha sido resuelto contacta con el colegio para ayudarte.',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                        }).then(async (resultSwal) => {
                            if (resultSwal.isConfirmed) {
                                Swal.close();
                            }
                        });
                    } else {
                        const generalBingoData = {
                            idCard: idCard,
                            name: nameParams,
                            lastName: lastNameParams,
                            email: emailParams,
                            // Datos de la transacción
                            amount: amountParams,
                            reference: referenceParams,
                            result: resultParams,
                            timestamp: timestampParams,
                            message: messageParams,
                            signature: signatureParams,
                            status: 'payed',
                            type: 'transkbank',
                            ticketType: 'general',
                            payedAt: new Date()
                        };

                        try {
                            let res = await createNewBingo(idCard, generalBingoData);
                            console.log("Bingo general creado:", res);
                            handleEmail('general', `${nameParams} ${lastNameParams}`, emailParams, [], idCard);

                            if (res) {
                                setActiveStep(3);
                                Swal.close();
                            }
                        } catch (error) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al crear bingo',
                                text: `No se pudo crear el registro para el ID: ${idCard}. Error: ${error.message}`
                            });
                        }
                    }
                } else if (studentsParams) {
                    console.log("Caso de pago con estudiantes");

                    let arrExists = await Promise.all(
                        studentsParams.map(async (el) => {
                            let res = await getBingoStatus(el.idCard);
                            return {
                                id: el.idCard,
                                status: res === 'ya existe' ? 'ya existe' : 'no existe'
                            };
                        })
                    );
                    const existingCards = arrExists.filter(el => el.status === 'ya existe');
                    console.log(existingCards);

                    if (existingCards.length > 0) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ha ocurrido un error',
                            text: 'La entrada ya se encuentra pagada. Si tu caso no ha sido resuelto contacta con el colegio para ayudarte.',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                        }).then(async (resultSwal) => {
                            if (resultSwal.isConfirmed) {
                                Swal.close();
                            }
                        });
                    } else {
                        let results = await Promise.all(
                            studentsParams.map(async (el) => {
                                try {
                                    const newBingoData = {
                                        idCard: el.idCard,
                                        name: nameParams,
                                        lastName: lastNameParams,
                                        course: el.course,
                                        email: emailParams,
                                        studentToPay: {
                                            name: el.nameStudent,
                                            course: el.course,
                                            idCard: el.idCard
                                        },
                                        students: studentsParams,
                                        // Datos de la transacción
                                        amount: amountParams,
                                        reference: referenceParams,
                                        result: resultParams,
                                        timestamp: timestampParams,
                                        message: messageParams,
                                        signature: signatureParams,
                                        status: 'payed',
                                        type: 'transbank',
                                        ticketType: 'alumno',
                                        payedAt: new Date()
                                    };

                                    console.log("Creando con esta data", el.idCard, newBingoData);

                                    let res = await createNewBingo(el.idCard, newBingoData);

                                    return {
                                        id: el.idCard,
                                        status: res ? 'Bingo creado' : 'Error al crear bingo'
                                    };
                                } catch (error) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error al crear bingo',
                                        text: `No se pudo crear el bingo para el estudiante con ID: ${el.idCard}. Error: ${error.message}`
                                    });

                                    return {
                                        id: el.idCard,
                                        status: 'Error al crear bingo'
                                    };
                                }
                            })
                        );

                        const failedBingos = results.filter(result => result.status === 'Error al crear bingo');
                        if (failedBingos.length > 0) {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Errores en la creación de bingos',
                                text: `No se pudieron crear ${failedBingos.length} bingos. Revisa la consola para más detalles.`
                            });
                        } else {
                            handleEmail('alumno', `${nameParams} ${lastNameParams}`, emailParams, studentsParams, '-')
                            setActiveStep(3);
                            Swal.close();
                        }
                    }
                }
            } else if (res === 'canceled') {
                setOpenModal1(false)
                setName(null)
                setAmount(null)
                setEmail(null)
                setEmailConfirm(null)
                setGenIdCard(null)
                setHasLoaded(false)
                setType(null)
                setLastName(null)
                setStudents([{ idCard: '', nameStudent: '', course: '' }])
                setActiveStep(0)
                Swal.fire({
                    icon: 'warning',
                    title: 'Pago cancelado',
                    text: 'El proceso de pago ha sido cancelado.',
                    confirmButtonText: 'Cerrar',
                    showCancelButton: false,
                })
            }
            setHasLoaded(true);
        };

        executeAsyncTask();

    }, [location.search]);

    useEffect(() => {
        if (bingoStudents && !bingoStudents.some(isStudentEmpty)) {
            qrRefs.current = bingoStudents.map((_, i) => qrRefs.current[i] || React.createRef());
        }
    }, [students]);

    useEffect(() => {
        if (qrRef.current) {
            setIsRendered(true);
            console.log('QR está renderizado y disponible:', qrRef.current);
        }
    }, []);

    useEffect(() => {
        async function getData() {
            Swal.fire({
                customClass: {
                    popup: 'swal-overlay'
                },
                imageUrl: loadingImg,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            const response = await getBingoStudents();
            setBingoStudents(response)
            Swal.close();
        }

        getData();
    }, [])


    const handleStudentChange = (index, field, value) => {
        const updatedStudents = [...students];
        updatedStudents[index][field] = value;
        setStudents(updatedStudents);
    };

    const handleDownload = () => {
        if (!genIdCard) {
            students.forEach((student, index) => {
                console.log(qrRefs);

                const qrElement = qrRefs.current[index].current;

                html2canvas(qrElement, { backgroundColor: null }).then(canvas => {
                    const image = canvas.toDataURL('image/png');
                    const link = document.createElement('a');
                    link.href = image;
                    link.download = `N°_${student.idCard} - Bingo La Salle.png`;
                    link.click();
                });
            })
        } else {
            const qrElement = qrRef.current;

            html2canvas(qrElement, { backgroundColor: null }).then(canvas => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = image;
                link.download = `N°_${genIdCard} - Bingo La Salle.png`;
                link.click();
            });
        }
    }



    const handleRemoveStudent = (index) => {
        const updatedStudents = students.filter((_, i) => i !== index);
        setStudents(updatedStudents);
    };

    const generatePDFWithQR = (genIdCard, qrBase64) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [480, 300]
        });

        const leftSectionWidth = 30;
        doc.setFillColor(223, 242, 255);
        doc.rect(0, 0, leftSectionWidth, 300, 'F');

        const textX = leftSectionWidth / 2;
        const textY = (300 / 2) + (doc.getTextWidth(`NUMERO: ${genIdCard}`) / 2);
        doc.setFontSize(14);
        doc.setTextColor(0, 51, 85);

        doc.text(`NÚMERO: ${genIdCard}`, textX, textY, { angle: 90 });
        const middleSectionWidth = 350;
        const middleSectionX = leftSectionWidth;
        doc.setFillColor(255, 255, 255);
        doc.rect(middleSectionX, 0, middleSectionWidth, 300, 'F');


        doc.setFontSize(28);
        doc.setTextColor(0, 51, 85);
        doc.text('Gran Bingo 2024', middleSectionX + (middleSectionWidth / 2) - (doc.getTextWidth('Gran Bingo 2024') / 2), 80);

        doc.addImage(logoBlue, 'PNG', middleSectionX + (middleSectionWidth / 2) - 50, 100, 100, 35);

        const boxWidth = 170;
        const boxX = middleSectionX + (middleSectionWidth / 2) - (boxWidth / 2);
        const boxY = 150;

        doc.setFontSize(14);
        doc.setTextColor(255, 255, 255);
        doc.setFillColor(0, 51, 85);
        doc.rect(boxX, boxY, boxWidth, 45, 'F');

        const firstText = 'SÁBADO 16 DE NOVIEMBRE';
        const firstTextWidth = doc.getTextWidth(firstText);
        doc.text(firstText, boxX + (boxWidth / 2) - (firstTextWidth / 2), boxY + 20);


        const secondText = '15:00 HRS';
        const secondTextWidth = doc.getTextWidth(secondText);
        doc.text(secondText, boxX + (boxWidth / 2) - (secondTextWidth / 2), boxY + 35);

        const baseY = 230;
        const rowHeight = 30;


        doc.setFontSize(10);
        doc.setTextColor(0, 51, 85);
        doc.text('AGRADECEMOS LA COLABORACIÓN DE', middleSectionX + (middleSectionWidth / 2) - (doc.getTextWidth('AGRADECEMOS LA COLABORACIÓN DE') / 2), 210);

        const sponsors = [
            { url: sponsor1, width: 40, height: 15 },
            { url: sponsor2, width: 30, height: 30 },
            { url: sponsor3, width: 30, height: 30 },
            { url: sponsor4, width: 30, height: 30 },
            { url: sponsor5, width: 30, height: 30 },
            { url: sponsor6, width: 30, height: 30 },
            { url: sponsor7, width: 30, height: 30 },
            { url: sponsor8, width: 30, height: 30 },
            { url: sponsor9, width: 30, height: 30 },

        ];


        let totalWidth = sponsors.reduce((acc, sponsor) => acc + sponsor.width, 0) + (sponsors.length - 1) * 10;


        let sponsorX = middleSectionX + (middleSectionWidth / 2) - (totalWidth / 2);


        sponsors.forEach((sponsor) => {

            let sponsorY = baseY + (rowHeight - sponsor.height) / 2;


            doc.addImage(sponsor.url, 'PNG', sponsorX, sponsorY, sponsor.width, sponsor.height);


            sponsorX += sponsor.width + 10;
        });

        const rightSectionWidth = 100;
        doc.setFillColor(223, 242, 255);
        doc.rect(480 - rightSectionWidth, 0, rightSectionWidth, 300, 'F');

        const qrSize = 80;
        const qrXPosition = 480 - rightSectionWidth + (rightSectionWidth - qrSize) / 2;
        doc.addImage(qrBase64, 'PNG', qrXPosition, 100, qrSize, qrSize);


        return doc.output('datauristring');
    };



    let imagesWithHtml = []

    const generateQRBase64 = async (value, studentsProp, type, idCard) => {
        console.log("Students es", studentsProp);

        const base64Images = [];
        imagesWithHtml = []

        if (studentsProp && !studentsProp.some(isStudentEmpty)) {
            console.log("entra a if");


            try {
                if (type === 'alumno') {
                    for (let i = 0; i < studentsProp.length; i++) {
                        const qrBase64 = await QRCode.toDataURL(`${value}${generateIdEncrypted2(studentsProp[i].idCard)}`, { type: 'image/png' });
                        base64Images.push({
                            base64: qrBase64,
                            code: studentsProp[i].idCard
                        })
                        let res = generatePDFWithQR(studentsProp[i].idCard, qrBase64.split(',')[1].trim(),)
                        imagesWithHtml.push({
                            base64: res,
                            code: studentsProp[i].idCard
                        })
                        console.log(imagesWithHtml);

                    }
                } else {
                    const qrBase64 = await QRCode.toDataURL(`${value}${generateIdEncrypted2(idCard)}`, { type: 'image/png' });
                    base64Images.push({
                        base64: qrBase64,
                        code: idCard
                    })
                    let res = generatePDFWithQR(idCard, qrBase64.split(',')[1].trim(),)
                    imagesWithHtml.push({
                        base64: res,
                        code: idCard
                    })
                }
                console.log("retorna ", base64Images);

                return base64Images;
            } catch (error) {
                console.error('Error generando el QR en base64:', error);
                return null;
            }
        } else {
            try {
                const qrBase64 = await QRCode.toDataURL(`${value}${generateIdEncrypted2(genIdCard)}`, { type: 'image/png' });
                base64Images.push(qrBase64)
                return base64Images;
            } catch (error) {
                console.error('Error generando el QR en base64:', error);
                return null;
            }
        }
    };


    const handleEmail = async (type, name, email, students1, idCard) => {
        try {
            const base64Images = await generateQRBase64(`https://salle-temuco.maxipos.cl/viewBingo?id=`, students1, type, idCard);
            await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendVoucherAndQR', {
                name: name,
                email: email,
                students: students1,
                boletos: imagesWithHtml,
                type: type,
                idCard: idCard
            });
        } catch (error) {
            console.error("Error enviando el correo:", error);
            Swal.close();
            Swal.fire("Error", "Hubo un problema enviando el correo", "error");
        }
    };

    const generateIdEncrypted2 = (id) => {
        let res = CryptoJS.AES.encrypt(id, process.env.REACT_APP_ENCRYPT_KEY).toString()
        return res
    }



    const isStudentEmpty = (student) => {
        return (
            !student.idCard &&
            !student.nameStudent &&
            !student.course
        );
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'emailConfirm':
                setEmailConfirm(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            default:
                break;
        }
    };


    const handleNextContinue = async () => {
        Swal.fire({
            customClass: {
                popup: 'swal-overlay'
            },
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });

        const showError = (message) => {
            Swal.fire({
                title: "Error",
                text: message,
                icon: "error",
                confirmButtonText: "Aceptar"
            });
        };

        if (type === 'general' && !genIdCard) {
            showError("Por favor ingresa número de la tarjeta.");
            return;
        } else
            if (!name) {
                Swal.fire({
                    title: "Error",
                    text: "Por favor ingresa tu nombre.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } else if (!email) {
                Swal.fire({
                    title: "Error",
                    text: "Por favor ingresa tu correo electrónico.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } else if (!emailConfirm) {
                Swal.fire({
                    title: "Error",
                    text: "Por favor confirma tu correo electrónico.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } else if (email !== emailConfirm) {
                Swal.fire({
                    title: "Error",
                    text: "Los correos electrónicos no coinciden.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } else if (type === 'alumno' && students.length === 0) {
                Swal.fire({
                    title: "Error",
                    text: "Debes agregar al menos un estudiante.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } else if (type === 'alumno') {
                const incompleteStudent = students.find(student => !student.nameStudent || !student.course);

                const idCards = students.map(student => formatBoleto(student.idCard));

                const hasDuplicates = idCards.some((id, idx) => idCards.indexOf(id) !== idx);

                if (hasDuplicates) {
                    showError("Hay números de tarjeta duplicados en la lista de estudiantes. Si usted desea una nueva tarjeta, contáctese con el presidente del curso correspondiente.");
                    return;
                }

                if (incompleteStudent) {
                    Swal.fire({
                        title: "Error",
                        text: "Todos los campos de los estudiantes deben estar completos.",
                        icon: "error",
                        confirmButtonText: "Aceptar"
                    });
                } else {
                    let cards = await Promise.all(
                        students.map(async (el) => {
                            let res = await getBingoStatus(formatBoleto(el.idCard));
                            return {
                                id: el.idCard,
                                status: res === 'ya existe' ? 'ya existe' : 'no existe'
                            };
                        })
                    );

                    const existingCards = cards.filter(el => el.status === 'ya existe');

                    if (existingCards.length === 0) {
                        setActiveStep((prevStep) => prevStep + 1);
                        Swal.close();
                    } else {
                        Swal.fire({
                            title: "Tarjeta pagada",
                            text: `La tarjeta con el número ${existingCards.map(card => card.id).join(", ")} ya se encuentra pagada. Verifica el número de tu tarjeta.`,
                            icon: 'info',
                            confirmButtonText: "Aceptar"
                        });
                    }
                }
            } else if (type === "general") {
                let res = await getBingoStatus(genIdCard);
                if (res === 'ya existe') {
                    Swal.fire({
                        title: "Tarjeta pagada",
                        text: `La tarjeta con el número ${genIdCard} ya se encuentra pagada. Verifica el número de tu tarjeta.`,
                        icon: 'info',
                        confirmButtonText: "Aceptar"
                    });
                } else {
                    setActiveStep((prevStep) => prevStep + 1);
                    Swal.close();
                }
            }
    };


    const handleNextFirst = (typeIn) => {
        setActiveStep((prevStep) => prevStep + 1);
        setType(typeIn)
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleBackClean = () => {
        setName(null)
        setAmount(null)
        setEmail(null)
        setEmailConfirm(null)
        setGenIdCard(null)
        setHasLoaded(false)
        setType(null)
        setLastName(null)
        setStudents([{ idCard: '', nameStudent: '', course: '' }])
        navigate('/bingo')
        setActiveStep(0)
    }

    const handleHomeBack = () => {

        setName(null)
        setAmount(null)
        setEmail(null)
        setEmailConfirm(null)
        setGenIdCard(null)
        setHasLoaded(false)
        setType(null)
        setLastName(null)
        setStudents([{ idCard: '', nameStudent: '', course: '' }])
        navigate('/bingo')
        setActiveStep(0)
    }

    const handlePayment = async () => {


        Swal.fire({
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando el pago...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });


        const secretKey = process.env.REACT_APP_FIREBASE_SECRET_KEY;

        let random = generateRandomString(8);
        let referenceID = `${random}`
        let document = {
            x_account_id: '95493506',//"03133479",
            x_amount: (value * students.length).toString(),
            x_currency: "CLP",
            x_customer_email: email,
            x_customer_first_name: name,
            x_customer_last_name: lastName,
            x_customer_phone: "",
            x_description: "Pago de bingo",
            x_reference: referenceID,
            x_shop_country: "CL",
            x_shop_name: "La Salle Temuco",
            x_url_callback: "https://salle-temuco.maxipos.cl/bingo",//"http://localhost:3000/bingo",
            x_url_cancel: "https://salle-temuco.maxipos.cl/bingo?res=canceled&",
            x_url_complete: type === 'general' ?
                `https://salle-temuco.maxipos.cl/bingo?res=completed&name=${encodeURIComponent(name)}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}&idCard=${encodeURIComponent(genIdCard)}&` :
                `https://salle-temuco.maxipos.cl/bingo?res=completed&name=${encodeURIComponent(name)}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}&students=${encodeURIComponent(JSON.stringify(students))}&`
        };


        let signature = getSignature(document, secretKey);



        document.x_signature = signature;

        // const queryString = new URLSearchParams(document).toString();

        try {
            //http://127.0.0.1:5001/apoderados-fd064/us-central1/payment
            fetch('https://us-central1-apoderados-fd064.cloudfunctions.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(document),
            })
                .then(response => response.json())
                .then(data => {
                    // setResponse(data);
                    if (data.url) {
                        const url = data.url;
                        const parts = url.split("/");
                        window.location.replace(data.url);
                    }
                })
                .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error('Error:', error);
        }

    };


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{
                            color: colors.yellow,
                            textAlign: 'center'
                        }}>
                            Bienvenido al Sistema de Pago del Gran Bingo 2024 del Colegio De La Salle Temuco
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: isMobile ? '90%' : '60%',
                                bgcolor: '#DFF2FF',
                                borderRadius: '12px',
                                padding: isMobile ? '16px' : '24px',
                                textAlign: 'center',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                mx: 'auto',
                                mt: 4,
                            }}
                        >
                            <Typography variant="body1" color="#06314E" gutterBottom>
                                Si deseas apoyar a un estudiante de colegio, elige la opción “Bingo para un curso”. Si no conoces a ningún estudiante, puedes optar por una entrada general seleccionando “Bingo entrada general”.
                            </Typography>

                            <p style={{
                                fontSize: '12px',
                                color: '#094B71'
                            }}><strong>Nota:</strong> Recuerda que el cartón de bingo se entregará el día del evento.</p>

                            <Button
                                variant="contained"
                                fullWidth
                                endIcon={<ArrowForwardIos />}
                                sx={{
                                    color: '#EFF9FF',
                                    borderRadius: '15px',
                                    bgcolor: '#007190',
                                    textTransform: 'none',
                                    mt: 2,
                                    height: isMobile ? '45px' : '50px',
                                    mb: 2,
                                    '&:hover': {
                                        bgcolor: '#007070',
                                    },
                                }}
                                onClick={() => {
                                    handleNextFirst('alumno')
                                }}
                            >
                                Bingo para un curso
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                endIcon={<ArrowForwardIos />}
                                sx={{
                                    borderRadius: '15px',
                                    bgcolor: '#007190',
                                    textTransform: 'none',
                                    height: isMobile ? '45px' : '50px',
                                    color: '#EFF9FF',
                                    mb: 2,
                                    '&:hover': {
                                        bgcolor: '#007070'
                                    },
                                }}
                                onClick={() => {
                                    handleNextFirst('general')
                                }}
                            >
                                Bingo entrada general
                            </Button>
                            <br />
                            <Box sx={{
                                width: '100%',
                                backgroundColor: '#B8E7FF',
                                borderRadius: '10px',
                                height: '60px',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <Typography sx={{
                                    color: colors.blue,
                                }}>
                                    Valor del bingo
                                </Typography>
                                <Typography sx={{
                                    color: colors.blue,
                                    fontSize: '20px',
                                    fontWeight: 'bold'
                                }}>
                                    $10.000 (Adhesión voluntaria)
                                </Typography>
                            </Box>
                        </Box>
                        <br />
                        <Rewards />
                        <Box
                            sx={{
                                backgroundColor: '#004b71', // Color de fondo del contenedor
                                borderRadius: '10px',
                                padding: '20px',
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                justifyItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <Grid container justifyContent="center" alignItems="center">
                                {sponsors.map((sponsor, index) => (
                                    <Grid item key={index}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0 10px',
                                            }}
                                        >
                                            <img
                                                src={sponsor}
                                                alt={`sponsor-${index}`}
                                                style={{
                                                    width: '60px',
                                                    height: 'auto',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <>
                        <Typography variant={isMobile ? "h6" : "h5"} gutterBottom sx={{
                            color: colors.yellow,
                            textAlign: 'center'
                        }}>
                            Formulario de compra
                        </Typography>
                        <Box
                            sx={{
                                overflow: 'hidden',
                                '&::-webkit-scrollbar': { display: 'none' },
                                scrollbarWidth: '10px',
                                maxWidth: isMobile ? 350 : 600,
                                mx: 'auto',
                                mt: 1,
                                p: isMobile ? 2 : 3,
                                borderRadius: 4,
                                bgcolor: '#DFF2FF',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6" color={'#035A89'} gutterBottom textAlign="center">
                                Datos de comprador
                            </Typography>
                            <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>
                                {
                                    type === "general" && (
                                        <Box>
                                            <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Número de tarjeta</Typography>
                                            <TextField
                                                placeholder="Número de tarjeta"
                                                variant="outlined"
                                                name="idCard"
                                                value={genIdCard}
                                                onChange={(e) => {
                                                    setGenIdCard(e.target.value)
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '12px',
                                                        backgroundColor: '#06314E17',
                                                        color: '#0090B5',
                                                        '& fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: '#00796B',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>
                                <Box>
                                    <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Nombre</Typography>
                                    <TextField
                                        variant="outlined"
                                        name="name"
                                        value={name}
                                        onChange={handleInputChange}
                                        placeholder='Nombre'
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                backgroundColor: '#06314E17',
                                                color: '#0090B5',
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#00796B',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Apellido</Typography>
                                    <TextField
                                        variant="outlined"
                                        name="lastName"
                                        value={lastName}
                                        onChange={handleInputChange}
                                        placeholder='Apellido'
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                backgroundColor: '#06314E17',
                                                color: '#0090B5',
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#00796B',
                                            },
                                        }}
                                    />
                                </Box>

                            </Box>

                            <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>
                                <Box>
                                    <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Email</Typography>
                                    <TextField
                                        placeholder="Email"
                                        variant="outlined"
                                        name="email"
                                        type='email'
                                        value={email}
                                        onChange={handleInputChange}
                                        sx={{
                                            width: '100%',

                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                backgroundColor: '#06314E17',
                                                color: '#0090B5',
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#00796B',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Repetir email</Typography>
                                    <TextField
                                        placeholder="Repetir Email"
                                        variant="outlined"
                                        name="emailConfirm"
                                        type='email'
                                        value={emailConfirm}
                                        onChange={handleInputChange}
                                        sx={{
                                            width: '100%',

                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                                backgroundColor: '#06314E17',
                                                color: '#0090B5',
                                                '& fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#00796B',
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>

                            {type !== 'general' && <Typography variant="h6" color={'#035A89'} gutterBottom textAlign="center" mt={4}>
                                Información del Estudiante
                            </Typography>}

                            {type !== 'general' &&
                                students.map((student, index) => (
                                    <>
                                        {student.isComplete ? (
                                            // Mostrar alumno completo como ListTile
                                            <Box key={index} display="flex" flexDirection="column" p={2} mb={1} border="1px solid #e0e0e0" borderRadius="8px">
                                                <Typography variant="h6" color="#06314E">
                                                    Bingo {index + 1}
                                                </Typography>
                                                <Typography color="#0090B5">
                                                    Curso: {student.course}
                                                </Typography>
                                                <Typography color="#0090B5">
                                                    Nombre: {student.nameStudent}
                                                </Typography>
                                                <Typography color="#0090B5">
                                                    N° tarjeta: {formatBoleto(student.idCard)}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            // Mostrar formulario editable si el alumno no está completo
                                            <Box key={index} display="grid" gridTemplateColumns={isMobile ? "1fr" : "0.5fr 1fr 1fr"} gap={1} mb={1} alignItems="center" justifyContent="center">
                                                {/* Selección de Curso */}
                                                <Box>
                                                    <Typography color="#06314E" sx={{ fontSize: '15px' }}>Curso</Typography>
                                                    <FormControl fullWidth sx={{ mb: 0 }}>
                                                        <Select
                                                            value={student.course || ""}
                                                            displayEmpty
                                                            onChange={(e) => {

                                                                const selectedCourse = e.target.value;
                                                                console.log("Busco los estudiantes de", selectedCourse);

                                                                console.log(selectedCourse);

                                                                console.log(bingoStudents);



                                                                const filteredStudents = bingoStudents.filter((s) => s.curso === selectedCourse);
                                                                setStudentsInCourse(filteredStudents);

                                                                console.log(filteredStudents);

                                                                handleStudentChange(index, 'course', selectedCourse);
                                                                handleStudentChange(index, 'selectedStudent', null);
                                                                handleStudentChange(index, 'idCard', '');

                                                            }}
                                                            placeholder="Selecciona un curso"
                                                            sx={{
                                                                fontSize: '13px',
                                                                color: "#0090B5",
                                                                backgroundColor: '#06314E17',
                                                                borderRadius: '12px',
                                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">Selecciona un curso</MenuItem>
                                                            <MenuItem value="Pre-Kinder-A">Pre Kinder A</MenuItem>
                                                            <MenuItem value="Pre-Kinder-B">Pre Kinder B</MenuItem>
                                                            <MenuItem value="Kinder-A">Kinder A</MenuItem>
                                                            <MenuItem value="Kinder-B">Kinder B</MenuItem>
                                                            <MenuItem value="Kinder-C">Kinder C</MenuItem>

                                                            <MenuItem value="Primero-A">Primero Básico A</MenuItem>
                                                            <MenuItem value="Primero-B">Primero Básico B</MenuItem>
                                                            <MenuItem value="Primero-C">Primero Básico C</MenuItem>

                                                            <MenuItem value="Segundo-A">Segundo Básico A</MenuItem>
                                                            <MenuItem value="Segundo-B">Segundo Básico B</MenuItem>
                                                            <MenuItem value="Segundo-C">Segundo Básico C</MenuItem>

                                                            <MenuItem value="Tercero-A">Tercero Básico A</MenuItem>
                                                            <MenuItem value="Tercero-B">Tercero Básico B</MenuItem>
                                                            <MenuItem value="Tercero-C">Tercero Básico C</MenuItem>

                                                            <MenuItem value="Cuarto-A">Cuarto Básico A</MenuItem>
                                                            <MenuItem value="Cuarto-B">Cuarto Básico B</MenuItem>
                                                            <MenuItem value="Cuarto-C">Cuarto Básico C</MenuItem>

                                                            <MenuItem value="Quinto-A">Quinto Básico A</MenuItem>
                                                            <MenuItem value="Quinto-B">Quinto Básico B</MenuItem>

                                                            <MenuItem value="Sexto-A">Sexto Básico A</MenuItem>
                                                            <MenuItem value="Sexto-B">Sexto Básico B</MenuItem>

                                                            <MenuItem value="Séptimo-A">Séptimo Básico A</MenuItem>
                                                            <MenuItem value="Séptimo-B">Séptimo Básico B</MenuItem>
                                                            <MenuItem value="Séptimo-C">Séptimo Básico C</MenuItem>

                                                            <MenuItem value="Octavo-A">Octavo Básico A</MenuItem>
                                                            <MenuItem value="Octavo-B">Octavo Básico B</MenuItem>

                                                            <MenuItem value="Primero-Medio-A">Primero Medio A</MenuItem>
                                                            <MenuItem value="Primero-Medio-B">Primero Medio B</MenuItem>

                                                            <MenuItem value="Segundo-Medio-A">Segundo Medio A</MenuItem>
                                                            <MenuItem value="Segundo-Medio-B">Segundo Medio B</MenuItem>

                                                            <MenuItem value="Tercero-Medio-A">Tercero Medio A</MenuItem>

                                                            <MenuItem value="Cuarto-Medio-A">Cuarto Medio A</MenuItem>
                                                            <MenuItem value="Cuarto-Medio-B">Cuarto Medio B</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <Box >
                                                    <Typography color="#06314E" sx={{ fontSize: '15px' }}>Nombre</Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={student.selectedStudent || ""}
                                                            displayEmpty
                                                            onChange={(e) => handleStudentSelectChange(index, e.target.value)}
                                                            placeholder="Selecciona un estudiante"
                                                            sx={{
                                                                color: "#0090B5",
                                                                backgroundColor: '#06314E17',
                                                                fontSize: '13px',
                                                                borderRadius: '12px',
                                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">Selecciona un estudiante</MenuItem>
                                                            {studentsInCourse
                                                                .map(student => (
                                                                    <MenuItem key={student.id} value={student.id}>
                                                                        {capitalizeWords(student.apellido)}, {capitalizeWords(student.nombre)}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <Box sx={{
                                                    minWidth: '70px'
                                                }}>
                                                    <Typography color="#06314E" sx={{ fontSize: '15px' }}>N° tarjeta</Typography>
                                                    <Box sx={{
                                                        display: 'flex'
                                                    }}>
                                                        <TextField
                                                            variant="outlined"
                                                            name="idCard"
                                                            // disabled={!student.enableEdit}
                                                            onChange={(e) => {
                                                                handleStudentChange(index, 'idCard', e.target.value);
                                                            }}
                                                            value={student.idCard || ""}
                                                            placeholder="N° tarjeta"
                                                            sx={{
                                                                fontSize: '13px',
                                                                width: '100%',
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '12px',
                                                                    backgroundColor: '#06314E17',
                                                                    color: '#0090B5',
                                                                    '& fieldset': { borderColor: 'transparent' },
                                                                },
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Box
                                                                            onClick={handlePopoverOpen}
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                border: `1px solid ${colors.blue}`,
                                                                                borderRadius: '50%',
                                                                                cursor: 'pointer',
                                                                                backgroundColor: 'transparent',
                                                                            }}
                                                                        >
                                                                            <QuestionMark sx={{ color: colors.blueLight, fontSize: '16px' }} />
                                                                        </Box>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />

                                                      
                                                    </Box>
                                                    <Popover
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handlePopoverClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>Este es el número de la tarjeta que debió ser entregada al estudiante. Si tú tienes otro número puedes modificarlo.</Typography>
                                                    </Popover>
                                                </Box>

                                            </Box>
                                        )}

                                        {/* <Box key={index} display="grid" gridTemplateColumns={isMobile ? "1fr" : "0.5fr 1fr 1fr"} gap={1} mb={1} alignItems="center" justifyContent="center">
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Curso</Typography>
                                                <FormControl fullWidth sx={{ mb: 0 }}>
                                                    <Select
                                                        value={student.course || ""}
                                                        displayEmpty
                                                        onChange={(e) => {
                                                            const selectedCourse = e.target.value;
                                                            console.log("Busco los estudiantes de", selectedCourse);

                                                            const filteredStudents = bingoStudents.filter((s) => s.curso === selectedCourse);
                                                            setStudentsInCourse(filteredStudents);

                                                            // Actualizar el curso en el estudiante seleccionado
                                                            handleStudentChange(index, 'course', selectedCourse);
                                                        }}
                                                        placeholder='Selecciona un curso'
                                                        sx={{
                                                            color: student.course ? colors.blue : "#0090B5",
                                                            backgroundColor: '#06314E17',
                                                            borderRadius: '12px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: 'transparent'

                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: 'transparent'
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: 'transparent'
                                                            },
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor: colors.blue,
                                                                    color: 'white'
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem disabled value="">Selecciona un curso</MenuItem>
                                                        <MenuItem value="Pre-Kinder-A">Pre Kinder A</MenuItem>
                                                        <MenuItem value="Pre-Kinder-B">Pre Kinder B</MenuItem>
                                                        <MenuItem value="Kinder-A">Kinder A</MenuItem>
                                                        <MenuItem value="Kinder-B">Kinder B</MenuItem>
                                                        <MenuItem value="Kinder-C">Kinder C</MenuItem>

                                                        <MenuItem value="Primero-A">Primero Básico A</MenuItem>
                                                        <MenuItem value="Primero-B">Primero Básico B</MenuItem>
                                                        <MenuItem value="Primero-C">Primero Básico C</MenuItem>

                                                        <MenuItem value="Segundo-A">Segundo Básico A</MenuItem>
                                                        <MenuItem value="Segundo-B">Segundo Básico B</MenuItem>
                                                        <MenuItem value="Segundo-C">Segundo Básico C</MenuItem>

                                                        <MenuItem value="Tercero-A">Tercero Básico A</MenuItem>
                                                        <MenuItem value="Tercero-B">Tercero Básico B</MenuItem>
                                                        <MenuItem value="Tercero-C">Tercero Básico C</MenuItem>

                                                        <MenuItem value="Cuarto-A">Cuarto Básico A</MenuItem>
                                                        <MenuItem value="Cuarto-B">Cuarto Básico B</MenuItem>
                                                        <MenuItem value="Cuarto-C">Cuarto Básico C</MenuItem>

                                                        <MenuItem value="Quinto-A">Quinto Básico A</MenuItem>
                                                        <MenuItem value="Quinto-B">Quinto Básico B</MenuItem>

                                                        <MenuItem value="Sexto-A">Sexto Básico A</MenuItem>
                                                        <MenuItem value="Sexto-B">Sexto Básico B</MenuItem>

                                                        <MenuItem value="Séptimo-A">Séptimo Básico A</MenuItem>
                                                        <MenuItem value="Séptimo-B">Séptimo Básico B</MenuItem>
                                                        <MenuItem value="Séptimo-C">Séptimo Básico C</MenuItem>

                                                        <MenuItem value="Octavo-A">Octavo Básico A</MenuItem>
                                                        <MenuItem value="Octavo-B">Octavo Básico B</MenuItem>

                                                        <MenuItem value="Primero-Medio-A">Primero Medio A</MenuItem>
                                                        <MenuItem value="Primero-Medio-B">Primero Medio B</MenuItem>

                                                        <MenuItem value="Segundo-Medio-A">Segundo Medio A</MenuItem>
                                                        <MenuItem value="Segundo-Medio-B">Segundo Medio B</MenuItem>

                                                        <MenuItem value="Tercero-Medio-A">Tercero Medio A</MenuItem>

                                                        <MenuItem value="Cuarto-Medio-A">Cuarto Medio A</MenuItem>
                                                        <MenuItem value="Cuarto-Medio-B">Cuarto Medio B</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box sx={{
                                                maxWidth: '90%',
                                            }}>
                                                <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>Nombre</Typography>
                                                <FormControl fullWidth>
                                                    <Select
                                                        value={student.selectedStudent || ""}
                                                        displayEmpty
                                                        onChange={(e) => {
                                                            const selectedStudentId = e.target.value;
                                                            handleStudentChange(index, 'selectedStudent', selectedStudentId);
                                                            const student2 = studentsInCourse.find(student => student.id === e.target.value);
                                                            // setIdCardSelectedStudent(`${student.boleto}`)
                                                            handleStudentChange(index, 'idCard', student2.boleto);
                                                           
                                                        }}
                                                        // onChange={handleStudentSelect}
                                                        placeholder='Selecciona un estudiante'
                                                        sx={{

                                                            backgroundColor: '#06314E17',
                                                            borderRadius: '12px',
                                                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                                                        }}
                                                    >
                                                        <MenuItem disabled value="">Selecciona un estudiante</MenuItem>
                                                        {studentsInCourse.map((student) => (
                                                            <MenuItem key={student.id} value={student.id}>{capitalizeWords(student.apellido)}, {capitalizeWords(student.nombre)} </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box>
                                                <Typography color={'#06314E'} sx={{ fontSize: '15px' }}>N° tarjeta</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    name="idCard"
                                                    disabled
                                                    value={student.idCard || ""}
                                                    placeholder='N° tarjeta'
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '12px',
                                                            backgroundColor: '#06314E17',
                                                            color: '#0090B5',
                                                            '& fieldset': { borderColor: 'transparent' },
                                                            '&:hover fieldset': { borderColor: 'transparent' },
                                                            '&.Mui-focused fieldset': { borderColor: 'transparent' },
                                                        },
                                                    }}
                                                />
                                            </Box>

                                        </Box> */}

                                        {
                                            students.length > 0 && students[0].isComplete && (
                                                <center>
                                                    <IconButton onClick={() => handleRemoveStudent(index)} color={colors.blue} >
                                                        <Delete sx={{
                                                            color: 'red'
                                                        }} />
                                                    </IconButton>
                                                </center>
                                            )
                                        }
                                        <Divider sx={{
                                            m: 1
                                        }} />
                                    </>
                                ))}
                            {type !== 'general' && <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                disableElevation
                                sx={{ mt: 1, bgcolor: '#B8E7FF', borderRadius: '15px', textTransform: 'none', height: '50px', color: colors.blue }}
                                onClick={() => {
                                    handleAddStudent(students.length - 1)
                                }}
                            >
                                Agregar alumno
                            </Button>}

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disableElevation
                                sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px' }}
                                onClick={handleNextContinue}
                            >
                                Continuar
                            </Button>
                            <Button
                                variant="contained"
                                fullWidth
                                disableElevation
                                sx={{
                                    mt: 1, bgcolor: 'transparent', color: '#007190', border: '1px solid #007190', borderRadius: '15px', textTransform: 'none', height: '50px', ':hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={handleBackClean}
                            >
                                Volver
                            </Button>
                        </Box>
                    </>
                );
            case 2:
                return (
                    <>
                        <Typography variant={isMobile ? "h6" : "h5"} gutterBottom sx={{
                            color: colors.yellow,
                            textAlign: 'center'
                        }}>
                            Confirma la compra
                        </Typography>
                        <Box
                            sx={{
                                overflow: 'auto',
                                scrollbarWidth: '10px',
                                maxWidth: isMobile ? 350 : 500,
                                mx: 'auto',
                                mt: 4,
                                p: isMobile ? 2 : 3,
                                borderRadius: 4,
                                bgcolor: '#DFF2FF',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h5" color={'#035A89'} gutterBottom textAlign="left">
                                Detalles de la compra
                            </Typography>

                            <Box display="flex" justifyContent="space-between" >
                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Nombre comprador:</Typography>
                                <Typography color={'#06314E'}>{name} {lastName}</Typography>
                            </Box>


                            <Box display="flex" justifyContent="space-between" >
                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Email comprador:</Typography>
                                <Typography color={'#06314E'}>{email}</Typography>
                            </Box>
                            <Divider sx={{
                                mb: 1,
                                mt: 1
                            }} />




                            {type !== 'general' && students.length > 0 && (
                                <>
                                    {students.map((student, index) => (
                                        <>
                                            <Box key={index} display="flex" justifyContent="space-between">
                                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>N° Tarjeta:</Typography>
                                                <Typography color={'#06314E'}>{formatBoleto(student.idCard)}</Typography>
                                            </Box>
                                            <Box key={index} display="flex" justifyContent="space-between">
                                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Nombre:</Typography>
                                                <Typography color={'#06314E'}>{student.nameStudent}</Typography>
                                            </Box>
                                            <Box key={index} display="flex" justifyContent="space-between">
                                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Curso:</Typography>
                                                <Typography color={'#06314E'}>{student.course}</Typography>
                                            </Box>
                                            <Divider sx={{
                                                mb: 1,
                                                mt: 1
                                            }} />
                                        </>
                                    ))}

                                </>
                            )}

                            <Box display="flex" justifyContent="space-between" >
                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Cantidad:</Typography>
                                <Typography color={'#06314E'}>{students.length} entrada(s)</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" >
                                <Typography color={'#06314E99'} sx={{ fontSize: '15px' }}>Total:</Typography>
                                <Typography sx={{
                                    fontSize: '20px'
                                }} color={'#06314E'}>${value * students.length}</Typography>
                            </Box>
                            <Divider sx={{
                                mb: 1,
                                mt: 1
                            }} />

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disableElevation
                                sx={{ mt: 2, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px' }}
                                onClick={handlePayment}
                            // onClick={handleEmail}
                            >
                                Proceder al pago (${value * students.length})
                            </Button>

                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mt: 2, borderRadius: '15px', textTransform: 'none', borderColor: '#007190', color: '#007190', height: '50px', ':hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={handleBack}
                            >
                                Volver
                            </Button>
                        </Box >
                    </>
                );
            case 3:
                return (
                    <Box
                        sx={{
                            maxWidth: 600,
                            margin: 'auto',
                            padding: 4,
                            backgroundColor: '#DFF2FF',
                            borderRadius: 4,
                            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                            textAlign: 'center'
                        }}
                    >
                        <CheckCircleOutline sx={{ color: 'green', fontSize: 60 }} />
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: colors.blue }}>
                            Tu pago fue validado
                        </Typography>
                        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />


                        <Box sx={{ marginTop: 1, marginBottom: 1, width: '100%' }}>
                            <Typography style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                color: colors.blue
                            }}>Detalles de la compra</Typography>
                            <Box sx={{ marginTop: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                                    <Typography sx={{
                                        color: '#06314E'
                                    }}>Nombre</Typography>
                                    <Typography sx={{
                                        color: '#06314E'
                                    }}>{name}</Typography>
                                </Box>
                                {students && !students.some(isStudentEmpty) && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                                    <Typography sx={{
                                        color: '#06314E'
                                    }}>Cantidad</Typography>
                                    <Typography sx={{
                                        color: '#06314E'
                                    }}>{students.length}</Typography>
                                </Box>}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5, fontWeight: 'bold' }}>
                                    <Typography sx={{
                                        color: '#06314E'
                                    }}>Total</Typography>
                                    <Typography sx={{
                                        color: '#06314E',
                                        fontSize: '20px'
                                    }}>${amount}</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'none' }}>
                            {students && !students.some(isStudentEmpty) ? (
                                students.map((student, index) => (
                                    <Box key={index} ref={qrRefs.current[index]}>
                                        <BoletoComponent logoBingo={logoBingo} genIdCard={student.idCard} />
                                    </Box>
                                ))
                            ) : (
                                genIdCard && (
                                    <Box ref={qrRef}>
                                        <BoletoComponent logoBingo={logoBingo} genIdCard={genIdCard} />
                                    </Box>
                                )
                            )}
                        </Box>

                        <Box sx={{
                            display: 'flex', position: 'absolute',
                            left: '-9999px', // Mover el contenido fuera del área visible
                            top: 0, justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 2
                        }}>
                            {students && !students.some(isStudentEmpty) ? (
                                students.map((student, index) => (
                                    <>
                                        <Box key={index} ref={qrRefs.current[index]}
                                        >
                                            <BoletoComponent logoBingo={logoBingo} genIdCard={student.idCard} />
                                        </Box>
                                        <Divider />
                                    </>
                                ))
                            ) : (
                                genIdCard && (
                                    <Box sx={{
                                        display: 'flex',
                                        marginTop: '20px',
                                        alignContent: 'center',
                                        justifyItems: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} >

                                        <Box ref={qrRef}
                                        >
                                            <BoletoComponent logoBingo={logoBingo} genIdCard={genIdCard} />
                                        </Box>

                                    </Box>
                                )
                            )}
                        </Box>

                        <Typography sx={{ marginBottom: 2 }}>
                            Hemos enviado una copia del comprobante y del QR a tu correo electrónico.
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                            {capitalize(email)}
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disableElevation
                            sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '40px' }}
                            onClick={handleDownload}
                        >
                            Descargar código(s) QR
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            disableElevation
                            sx={{
                                mt: 1, bgcolor: 'transparent', color: '#007190', border: '1px solid #007190', borderRadius: '15px', textTransform: 'none', height: '40px', ':hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            onClick={handleHomeBack}
                        >
                            Volver a inicio
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div style={{
                backgroundColor: colors.blue,
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                overflow: 'hidden',
                '&::-webkit-scrollbar': { display: 'none' },
                zIndex: 0
            }} />

            <div style={{
                position: 'absolute',
                backgroundColor: colors.blue,
                // top: 0,
                // left: 0,
                width: '99vw',
                height: '95vh',
                zIndex: 1,
                overflow: 'hidden',
                '&::-webkit-scrollbar': { display: 'none' },
            }}>
                <Confetti
                    width={windowSize.width}
                    height={windowSize.height}
                    numberOfPieces={400}
                    gravity={0.01}
                    initialVelocityY={6}
                    // recycle={true}
                    colors={["#3DBD96BF", "#FDA5A5BF", "#FDA5A559", "#ECB43C73", "#56B8A5D9", "#ECB43CE5", "#56B8A599", "#3DBD9659", "#FDA5A5"]}
                    style={{
                        opacity: 0.7,
                    }}
                />
            </div>


            <Box sx={{
                width: '100%',
                height: '100%',
                maxWidth: 600,
                mx: 'auto',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                alignContent: 'center',
                mt: 1,
                backgroundColor: colors.blue,
                zIndex: 2,
                position: 'relative',
                p: isMobile ? 1 : 3,
                maxWidth: isMobile ? '90%' : 600,
                overflowY: 'auto',
                maxHeight: '92vh',
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
            }}>
                <center><img src={logoBingo} style={{
                    marginTop: '10px',
                    width: isMobile ? '70%' : '80%',
                }} /></center>
                <br />
                <Stepper sx={{
                    '& .MuiStepIcon-root': {
                        color: 'grey',
                        '&.Mui-active': {
                            color: colors.yellow,
                        },
                        '&.Mui-completed': {
                            color: 'white',
                        },
                    },
                    '& .MuiStepLabel-label': {
                        color: 'white',
                        '&.Mui-active': {
                            color: colors.yellow,
                            fontWeight: 'bold',
                        },
                        '&.Mui-completed': {
                            color: colors.green,
                        },
                    },
                    '& .MuiStep-root': {
                        borderRadius: '8px',
                        padding: isMobile ? '4px' : '8px',
                        marginBottom: isMobile ? '4px' : '8px',
                    },
                }} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderStepContent(activeStep)}
            </Box>
            <YouTubeDialog
                videoId="dQw4w9WgXcQ"
                title="Tutorial de pago"
                openByDefault={openModal1}
            />
        </>

    );
}

export default BingoHome;
