import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { getStudents } from '../database/students'; // Ajusta la ruta según tu configuración
import { Box, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Pagination, TextField, InputAdornment, ListItemSecondaryAction, Button, ListItemIcon } from '@mui/material';
import { Search } from '@mui/icons-material';
import colors from '../utils/colors';
import loadingImg from '../assets/loading.svg';
import { capitalize } from '../utils/capitalize';
import { getStudentsFromUnpaidFamilies } from '../database/family';
import PaymentModal from '../components/modal_pay';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';


export default function PendingPays() {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [pendingPays, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({});

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedStudents = filteredStudents.slice(startIndex, endIndex);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const location = useLocation();

    useEffect(() => {

        
        if (location.pathname.includes('res=cancel?x_account_id') || location.search.includes('res=cancel?x_account_id')) {
            // const searchParams = new URLSearchParams(location.search);
            // const accountIdParam = searchParams.get('x_account_id');
            // console.log('La URL contiene la palabra "cancel".', location);
            Swal.fire({
                title: "Pago cancelado",
                text: "Has cancelado el pago. Puedes intentarlo nuevamente.",
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.history.pushState({}, '', '/pendingPays');
                }
            });
        } else if (location.pathname.includes('completed') || location.search.includes('completed')) {

            Swal.fire({
                title: "Pago realizado",
                text: "Se ha realizado correctamente el pago.",
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.history.pushState({}, '', '/pendingPays');
                }
            });
        }
    }, [location]);


    useEffect(() => {
        const fetchStudents = async () => {
            setLoading(true);
            const response = await getStudentsFromUnpaidFamilies();
            console.log("Devuelve los estudiantes", response);
            setStudents(response);
            setLoading(false);
        };
        fetchStudents();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = pendingPays.filter(student =>
        (student.name && student.name.toLowerCase().includes(lowercasedFilter) ||
            student.lastName && student.lastName.toLowerCase().includes(lowercasedFilter))
        );
        setFilteredStudents(filteredData);
        setPage(1);
    }, [searchTerm, pendingPays]);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'pendingPays'} />
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
                    <p>Cargando pendientes...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                }}>
                    <ListItem sx={{ paddingX: 5 }}>
                        <ListItemText sx={{ color: colors.green }} primary={`Pagos pendientes (${pendingPays.length})`}
                            primaryTypographyProps={{
                                fontFamily: 'Nunito',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                color: colors.blue,
                                textAlign: 'center'
                            }} />
                    </ListItem>
                    <TextField
                        placeholder="Buscar estudiante por nombre o apellido"
                        variant='outlined'
                        sx={{
                            width: '90%',
                            mb: 1,
                            backgroundColor: colors.greyBack,
                            borderRadius: '50px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {paginatedStudents.map((student) => (
                        <ListItem
                            key={student.id}
                            sx={{
                                cursor: 'pointer',
                                margin: '2px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '10px',
                                border: '1px solid #1212121F',
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ backgroundColor: colors.blue }}>
                                    {student.name ? capitalize(student.name.substring(0, 1)) : '?'}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ ml: 1 }}
                                primaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                secondaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                primary={`${capitalize(student.name)} ${capitalize(student.lastName)}`}
                                secondary={`Cantidad de hijos: ${student.students.length}`}
                            />
                            <ListItemIcon>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        border: '1px solid',
                                        borderColor: colors.green,
                                        borderRadius: '10px',
                                        padding: '5px',
                                        color: colors.green,
                                        '&:hover': {
                                            backgroundColor: colors.green,
                                            color: 'white'
                                        }
                                    }}
                                    onClick={() => {
                                        setSelectedStudent(student);
                                        setIsOpen(true)
                                    }}
                                >Pagar</Button>
                            </ListItemIcon>
                        </ListItem>
                    ))}
                    <Pagination
                        count={Math.ceil(filteredStudents.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        sx={{ mt: 2 }}
                    />
                    {
                        isOpen && (
                            <PaymentModal handleClose={() => {
                                setIsOpen(false);
                            }}
                                idFamily={selectedStudent.familyId}
                                open={isOpen}
                                totalAmount={2500} />
                        )
                    }
                </Box>
            )}
        </div>
    );
}
