import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { getFamilyByRutRepresent } from '../database/family';
import colors from '../utils/colors';
import { Warning } from '@mui/icons-material';
import logo from '../assets/salle_logo_land.png';
import CryptoJS from 'crypto-js';
import { capitalize } from '../utils/capitalize';

function QrPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedRut = searchParams.get('rut');
    const [perfilData, setPerfilData] = useState(null);
    const [isValidRut, setIsValidRut] = useState(false);
    const [loading, setLoading] = useState(false);

    const decryptRut = (encryptedRut) => {
        const secretKey = 'nanana nanana';
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedRut, secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Error al descifrar el RUT:', error);
            return null;
        }
    };

    useEffect(() => {
        async function getData() {
            setLoading(true);

            if (encryptedRut) {
                const rutRepresent = decryptRut(encryptedRut);

                if (rutRepresent) {
                    setIsValidRut(true);
                    const res = await getFamilyByRutRepresent(rutRepresent);
                    if (res !== false && res !== 'not payed') {
                        setPerfilData(res);
                    } else {
                        setIsValidRut(false)
                    }
                } else {
                    setIsValidRut(false);
                }
            } else {
                setIsValidRut(false);
            }

            setLoading(false);
        }
        getData();
    }, [encryptedRut]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: colors.blue,
                color: 'white',
                textAlign: 'center'
            }}
        >
            <Container maxWidth="sm" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', padding: '20px', borderRadius: '10px', boxShadow: 3 }}>
                {
                    loading ? (
                        <CircularProgress sx={{ color: 'white' }} />
                    ) : isValidRut ? (
                        perfilData ? (
                            <>
                                <VerifiedUserIcon sx={{ fontSize: 100, marginBottom: 2 }} />
                                <Typography variant="h4" gutterBottom>
                                    {
                                        perfilData.type == 'mom' ? `Se verifica a ${capitalize(perfilData.mom.name)} ${capitalize(perfilData.mom.lastNameP)} como apoderado del establecimiento.` : perfilData.type == 'dad' ? `Se verifica a ${capitalize(perfilData.dad.name)} ${capitalize(perfilData.dad.lastNameP)} como apoderado del establecimiento.` : perfilData.type == 'student' ? `El estudiante ${capitalize(perfilData.dataStudent.name)} ${capitalize(perfilData.dataStudent.lastNameP)} está validado en el centro de estudio.` : 'No se ha encontrado el rut indicado.'
                                    }
                                </Typography>
                                <Typography variant="h6">
                                    Año: {perfilData.year}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Warning sx={{ fontSize: 100, marginBottom: 2 }} />
                                <Typography variant="h4" gutterBottom>
                                    No se han podido obtener los datos de este estudiante.
                                </Typography>
                            </>
                        )
                    ) : (
                        <Typography variant="h5">
                            Estudiante no encontrado.
                        </Typography>
                    )
                }
            </Container>
            <br />
            <br />
            <img src={logo} style={{ width: '200px' }} />
        </Box>
    );
}

export default QrPage;
