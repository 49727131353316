import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Box, TextField, Button, Container, Typography, CircularProgress, Alert } from '@mui/material';
import { getFamilyByRutRepresent } from '../database/family';
import colors from '../utils/colors';
import logo from '../assets/salle_logo_land.png';
import html2canvas from 'html2canvas';
import CryptoJS from 'crypto-js';


function GenerateQr() {
    const [rut, setRut] = useState('');
    const [url, setUrl] = useState('');
    const [isValidRut, setIsValidRut] = useState(null);
    const [loading, setLoading] = useState(false);
    const qrRef = useRef();

    const handleInputChange = (e) => {
        setRut(e.target.value);
    };

    const cifrarRUT = (rut) => {
        const secretKey = 'nanana nanana';
        return CryptoJS.AES.encrypt(rut, secretKey).toString();
    };

    const formatRut = (rut) => {
        let cleanRut = rut.replace(/[\.\-]/g, '').trim().toUpperCase();

        if (cleanRut.length < 8) return null;

        const dv = cleanRut.slice(-1);

        const num = cleanRut.slice(0, -1);

        const formattedNum = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return `${formattedNum}-${dv}`;
    };

    const generarQR = async () => {
        const formattedRut = formatRut(rut);

        setRut(formattedRut);
        setLoading(true);
        const res = await getFamilyByRutRepresent(formattedRut);
        if (res) {
            setIsValidRut(true);
            const encryptedRut = cifrarRUT(rut);
            const qrUrl = `https://salle.maxipos.cl/qr?rut=${encodeURIComponent(encryptedRut)}`;
            setUrl(qrUrl);
            console.log(qrUrl);

            setTimeout(() => {
                descargarQR(qrUrl);
            }, 500);
        } else {
            setIsValidRut(false);
            setUrl('');
        }
        setLoading(false);
    };

    const descargarQR = () => {
        const qrElement = qrRef.current;

        html2canvas(qrElement, { backgroundColor: null }).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = `QR_${rut}.png`;
            link.click();
        });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: colors.blue }}>
            <Container maxWidth="sm" sx={{ textAlign: 'center', padding: '20px', borderRadius: '10px', boxShadow: 3, backgroundColor: 'white' }}>
                <Typography variant="h4" gutterBottom>
                    Descarga tu QR
                </Typography>
                <Typography variant="h6" gutterBottom>
                    A continuación deberás ingresar tu rut y se descargará tu código QR. Puedes mostrarlo en diversos negocios para obtener descuentos.
                </Typography>
                <br />
                <TextField
                    label="Ingresa tu RUT"
                    variant="outlined"
                    fullWidth
                    value={rut}
                    onChange={handleInputChange}
                    sx={{ marginBottom: '20px', width: '60%', height: '6vh' }}
                />
                <br />
                <Button variant="contained" sx={{
                    width: '60%',
                    height: '6vh',
                    textTransform: 'none',
                }} color="primary" onClick={generarQR} disabled={loading}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : <span style={{
                        fontSize: '18px'
                    }} >Generar mi QR</span>}
                </Button>

                {isValidRut === false && (
                    <Alert severity="error" sx={{ marginTop: '20px' }}>
                        El RUT no fue encontrado en la base de datos.
                    </Alert>
                )}

                {url && (
                    <Box sx={{
                        display: 'flex',
                        marginTop: '20px',
                        alignContent: 'center',
                        justifyItems: 'center',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }} >
                        <Box ref={qrRef}
                            sx={{
                                p: 1,
                                backgroundColor: colors.blue,
                                border: '1px solid #06314e',
                                borderRadius: '10px'
                            }}>
                            <QRCode
                                size={512}
                                style={{
                                    width: '200px',
                                    height: '200px',
                                    border: '3px solid black'
                                }}
                                value={url} />
                            <br />
                            <img style={{
                                width: '200px',
                            }} src={logo} />
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

export default GenerateQr;
