const formatRUT = (value) => {
    // Remover puntos y guiones
    value = value.replace(/[.-]/g, '');

    // Formatear RUT con puntos y guion
    let rutFormatted = '';
    let rutBody = value.slice(0, -1);
    let dv = value.slice(-1);

    while (rutBody.length > 3) {
        rutFormatted = '.' + rutBody.slice(-3) + rutFormatted;
        rutBody = rutBody.slice(0, -3);
    }
    rutFormatted = rutBody + rutFormatted + '-' + dv;

    return rutFormatted;
};

export default formatRUT;