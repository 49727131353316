import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const getAllCourses = async () => {
    const coursesResponse = await getDocs(collection(db, 'courses'));
    const courses = coursesResponse.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    courses.sort((a, b) => a.name.localeCompare(b.name));
    return courses;
};