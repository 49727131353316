import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography, IconButton, Divider, Select, MenuItem, Grid } from '@mui/material';
import { Add, Close, Remove } from '@mui/icons-material';
import colors from '../utils/colors';
import { createFamilyMulti, getChildren } from '../database/family';
import Swal from 'sweetalert2';
import { getAllCourses } from '../database/courses';
import formatRUT from '../utils/format_rut';
import FinalDialogCreateFamily from './dialog_final_create_family';
import { capitalize } from '../utils/capitalize';
import loadingSVG from '../assets/loading.svg';
import { getValuePay } from '../database/pays';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', 
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    overflowY: 'auto'
};

const AddFamilyModal = ({ open, handleClose, reloadData, isView, data }) => {
    const [family, setFamily] = useState({
        id: '',
        dad: { name: '', lastNameP: '', lastNameM: '' },
        mom: { name: '', lastNameP: '', lastNameM: '' },
        address: '',
        payed: false,
        email: '',
        children: [{ enrollmentNumber: '', name: '', lastNameP: '', lastNameM: '', course: null }],
    });
    const [courses, setCourses] = useState([]);


    const [openDialog, setOpenDialog] = useState(false);
    const [amountPay, setAmountPay] = useState(0)

    const handleChange = (e, index, field, parentField) => {
        const value = e.target.value;

        if (parentField === 'children') {
            const newChildren = [...family.children];
            newChildren[index][field] = value;
            setFamily({ ...family, children: newChildren });
        } else if (parentField === 'dad' || parentField === 'mom') {
            setFamily({ ...family, [parentField]: { ...family[parentField], [field]: value } });
        } else {
            setFamily({ ...family, [field]: value });
        }
    };

    const handleChangeRut = (e, index, field, parentField) => {
        const formatted = formatRUT(e.target.value)

        if (parentField === 'children') {
            const newChildren = [...family.children];
            newChildren[index][field] = formatted;
            setFamily({ ...family, children: newChildren });
        } else {
            setFamily({ ...family, [parentField]: { ...family[parentField], [field]: formatted } });
        }
    };

    const addHijo = () => {
        setFamily({ ...family, children: [...family.children, { name: '', lastNameP: '', lastNameM: '', course: '' }] });
    };

    const removeHijo = (index) => {
        const newChildren = family.children.filter((_, i) => i !== index);
        setFamily({ ...family, children: newChildren });
    };

    const handleSave = async () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        async function initData() {
            const coursesRes = await getAllCourses();
            setCourses(coursesRes);
            const response = await getValuePay();
            console.log(response);

            setAmountPay(response);
            if (isView) {
                Swal.fire({
                    customClass: {
                        popup: 'swal-overlay'
                    },
                    imageUrl: loadingSVG,
                    imageWidth: 300,
                    imageHeight: 300,
                    title: "Cargando...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                const res = await getChildren(data.id);
                let children = res.map((child) => ({
                    id: child.id,
                    name: child.name,
                    lastNameP: child.lastNameP,
                    lastNameM: child.lastNameM,
                    course: child.course,
                    rut: child.rut,
                    enrollmentNumber: child.enrollmentNumber
                }));
                setFamily({
                    id: data.id,
                    dad: { rut: data.dad.rut, name: data.dad.name, lastNameP: data.dad.lastNameP, lastNameM: data.dad.lastNameM },
                    mom: { rut: data.mom.rut, name: data.mom.name, lastNameP: data.mom.lastNameP, lastNameM: data.mom.lastNameM },
                    address: data.address,
                    payed: data.payed,
                    children: children,
                    responsible: data.responsible,
                    email: data.email
                });
                Swal.close();
            }
        }

        initData();
    }, [data]);

    return (
        <Modal open={open} onClose={handleClose} style={{
            zIndex: 1
        }}>
            <Box sx={style}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Typography variant="h5" component="h2" style={{ marginBottom: '16px', color: colors.blue }}>
                            {isView ? `Editar Familia ${capitalize(data.dad.lastNameP)} ${capitalize(data.mom.lastNameP)}` : 'Agregar Familia'}
                        </Typography>
                        <Box sx={{
                            width: 'auto',
                            padding: '8px',
                            backgroundColor: family.payed ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 165, 0, 0.8)',
                            color: 'white',
                            borderRadius: '4px',
                            textAlign: 'center',
                            marginBottom: '16px',
                            ml: 2
                        }}>
                            <Typography variant="body1">
                                {family.payed ? `Pagado \$${amountPay}` : 'Deudor'}
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton
                        onClick={() => {

                            handleClose();
                        }}
                        sx={{
                            color: colors.blue
                        }} >
                        <Close />
                    </IconButton>
                </Box>
                <br />
                <Grid container spacing={2}>
                    <Grid container sx={{
                        border: '1px solid #e3e2e3',
                        padding: '10px',
                        borderRadius: '10px'
                    }}>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={12}>
                            <Typography variant="body1" component="p" sx={{ color: 'grey' }}>
                                Padre de Familia
                            </Typography>
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Rut padre"
                                fullWidth
                                value={family.dad.rut || ''}
                                onChange={(e) => {
                                    handleChangeRut(e, null, 'rut', 'dad')
                                }}
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Nombres"
                                fullWidth
                                value={family.dad.name || ''}
                                onChange={(e) => handleChange(e, null, 'name', 'dad')}
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Apellido Paterno"
                                fullWidth
                                value={family.dad.lastNameP || ''}
                                onChange={(e) => handleChange(e, null, 'lastNameP', 'dad')}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{
                            padding: '5px',
                            mb: 1
                        }}>
                            <TextField
                                label="Apellido Materno"
                                fullWidth
                                value={family.dad.lastNameM || ''}
                                onChange={(e) => handleChange(e, null, 'lastNameM', 'dad')}
                            />
                        </Grid>

                        {/* <Grid sx={{
                            padding: '5px'
                        }} item xs={12}>
                            <Typography variant="body1" component="p" sx={{ color: 'grey' }}>
                                Madre de Familia
                            </Typography>
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Rut madre"
                                fullWidth
                                value={family.mom.rut || ''}
                                onChange={(e) => handleChangeRut(e, null, 'rut', 'mom')
                                }
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Nombres"
                                fullWidth
                                value={family.mom.name || ''}
                                onChange={(e) => handleChange(e, null, 'name', 'mom')}
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Apellido Paterno"
                                fullWidth
                                value={family.mom.lastNameP || ''}
                                onChange={(e) => handleChange(e, null, 'lastNameP', 'mom')}
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={3}>
                            <TextField
                                label="Apellido Materno"
                                fullWidth
                                value={family.mom.lastNameM || ''}
                                onChange={(e) => handleChange(e, null, 'lastNameM', 'mom')}
                            />
                        </Grid> */}
                    </Grid>

                    <Grid container sx={{
                        border: '1px solid #e3e2e3',
                        padding: '10px',
                        borderRadius: '10px',
                        mt: 3
                    }}>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="p" sx={{ color: 'grey', padding: '5px' }}>
                                Datos de Familia
                            </Typography>
                        </Grid>
                        {/* Dirección */}
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={6}>
                            <TextField
                                label="Dirección de la familia"
                                fullWidth
                                value={family.address || ''}
                                onChange={(e) => handleChange(e, null, 'address', 'address')}
                            />
                        </Grid>
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={6}>
                            <TextField
                                type='email'
                                label="Email del responsable económico"
                                fullWidth
                                value={family.email || ''}
                                onChange={(e) => handleChange(e, null, 'email', 'email')}
                            />
                        </Grid>
                    </Grid>


                    <Grid container sx={{
                        border: '1px solid #e3e2e3',
                        padding: '10px',
                        borderRadius: '10px',
                        mt: 3
                    }}>
                        {/* Datos de los hijos */}
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={12}>
                            <Typography variant="body1" component="p" sx={{ color: 'grey' }}>
                                Hijos
                            </Typography>
                        </Grid>
                        {family.children.map((child, index) => (
                            <Grid container key={index}>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <TextField
                                        label="Número de matrícula"
                                        value={child.enrollmentNumber || ''}
                                        onChange={(e) => handleChange(e, index, 'enrollmentNumber', 'children')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <TextField
                                        label="Rut hijo"
                                        value={child.rut || ''}
                                        onChange={(e) => handleChangeRut(e, index, 'rut', 'children')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <TextField
                                        label="Nombres"
                                        value={child.name || ''}
                                        onChange={(e) => handleChange(e, index, 'name', 'children')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <TextField
                                        label="Apellido Paterno"
                                        value={child.lastNameP || ''}
                                        onChange={(e) => handleChange(e, index, 'lastNameP', 'children')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <TextField
                                        label="Apellido Materno"
                                        value={child.lastNameM || ''}
                                        onChange={(e) => handleChange(e, index, 'lastNameM', 'children')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid sx={{
                                    padding: '5px'
                                }} item xs={2}>
                                    <Select
                                        value={child.course || ""}
                                        sx={{
                                            color: child.course ? colors.black : 'grey'
                                        }}
                                        onChange={(e) => handleChange(e, index, 'course', 'children')}
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Selecciona el curso
                                        </MenuItem>
                                        {courses.map((course) => (
                                            <MenuItem key={course.id} value={course.id}>
                                                {course.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={1} sx={{
                                    padding: '5px',
                                    alignContent: 'center'
                                }}>
                                    {family.children.length > 1 && (
                                        <IconButton sx={{
                                            color: colors.blue
                                        }} onClick={() => removeHijo(index)}>
                                            <Remove />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        {/* Botón para agregar hijos */}
                        <Grid sx={{
                            padding: '5px'
                        }} item xs={12}>
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={addHijo}
                                style={{ marginBottom: '16px', color: colors.blue, borderColor: colors.blue }}
                            >
                                Agregar Hijo
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleSave}
                            style={{ backgroundColor: colors.blue, color: colors.white, textTransform: 'none', fontSize: '18px' }}
                        >

                            {isView ? (

                                'Cambiar responsables'

                            ) :
                                'Continuar'
                            }

                        </Button>
                    </Grid>
                </Grid>
                {
                    openDialog && (
                        <FinalDialogCreateFamily
                            isView={isView}
                            idFamily={family.id}
                            clearInputs={() => {
                                setFamily({
                                    id: '',
                                    dad: { name: '', lastNameP: '', lastNameM: '', rut: '' },
                                    mom: { name: '', lastNameP: '', lastNameM: '', rut: '' },
                                    address: '',
                                    email: '',
                                    children: [{ enrollmentNumber: '', name: '', lastNameP: '', lastNameM: '', course: '', rut: '' }],
                                });
                            }} dataFamily={family} onClose={() => setOpenDialog(false)} onCloseBack={() => {
                                handleClose();
                            }} reloadData={() => {
                                reloadData();
                            }} openDialog={openDialog} />
                    )
                }
            </Box>

        </Modal>
    );
};

export default AddFamilyModal;
