import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { Box, Typography } from '@mui/material';
import logoBlue from '../assets/salle_blue_text.png'
import colors from '../utils/colors'

import sponsor1 from "../assets/gifu6L2fYzx2bKDMxcz8BLBl0FZAw3.png"
import sponsor2 from "../assets/olsT8FesV8ZRtNCrGe5DVugMSoI593.png"
import sponsor3 from "../assets/aMLJ54g4vQY77HvXMlwIlAOt0iyhEW.png"
import sponsor4 from "../assets/cM5kpHNQ33TbQ0PWA9WZS1NgxufolG.png"
import sponsor5 from "../assets/9qrUzlZ37IOuQvCvhFbUj1aP2Lr8H0.png"
import sponsor6 from "../assets/cNYkgR3kmfqXEagLdf7XmXITkhlQTb.png"
import sponsor7 from "../assets/2GmuiCsLJ5ymr4ec2fgXDqxGafqRHI.png"

const BoletoComponent = ({ genIdCard }) => {

    const generateIdEncrypted2 = (id) => {
        let res = CryptoJS.AES.encrypt(id, process.env.REACT_APP_ENCRYPT_KEY).toString()
        console.log(res, id);
        return res
    }



    return (
        <Box sx={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            // justifyItems: 'legacy',
            width: '100%',
            m: 1,
            boxShadow: '2px 2px 2px 2px gray',
            borderRadius: '10px'
        }}>
            <Box sx={{
                backgroundColor: '#DFF2FF',
                justifyItems: 'center',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '20%',
                borderRadius: '10px'

            }}>
                <Typography style={{
                    transform: 'rotate(270deg)',
                    fontSize: '20px',
                    color: colors.blue
                }}>
                    Número: {genIdCard}
                </Typography>
            </Box>
            <Box sx={{
                justifyItems: 'center',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                backgroundColor: 'white'
            }}>
                <Typography sx={{
                    fontSize: '25px',
                    color: colors.blue
                }}>
                    Gran Bingo 2024
                </Typography>
                <img src={logoBlue} style={{
                    width: '150px'
                }} />
                <Box sx={{
                    width: '90%',
                    backgroundColor: colors.blue,
                    color: 'white'
                }}>
                    <Typography >
                        Sabado 16 de noviembre
                    </Typography>
                    <Typography>
                        15 horas
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{
                        fontSize: '10px'
                    }}>
                        Agradecemos la colaboración de
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}>
                        <img alt='imgSponsors1' src={sponsor1} style={{ width: '50px', height: '30px', padding: '2px' }} />
                        <img alt='imgSponsors2' src={sponsor2} style={{ width: '50px', padding: '2px' }} />
                        <img alt='imgSponsors3' src={sponsor3} style={{ width: '50px', padding: '2px' }} />
                        <img alt='imgSponsors4' src={sponsor4} style={{ width: '50px', padding: '2px' }} />
                        <img alt='imgSponsors5' src={sponsor5} style={{ width: '50px', padding: '2px' }} />
                        <img alt='imgSponsors6' src={sponsor6} style={{ width: '50px', padding: '2px' }} />
                        <img alt='imgSponsors7' src={sponsor7} style={{ width: '50px', padding: '2px' }} />

                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#DFF2FF',
                justifyItems: 'center',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                width: '25%',
                borderRadius: '10px'

            }}>
                <QRCodeCanvas
                    size={100} // Reducido el tamaño del QR a 120px
                    style={{
                        marginTop: '5px',
                        // width: '50px',
                        // height: '50px',
                        borderRadius: '10px',
                        padding: '2px',
                        backgroundColor: 'white',
                    }}
                    value={`https://salle-temuco.maxipos.cl/viewBingo?id=${generateIdEncrypted2(
                        genIdCard
                    )}`}
                />
                <Typography>
                    N°: {genIdCard}
                </Typography>
            </Box>
        </Box>
    )

};

export default BoletoComponent;