import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import ReusableDrawer from '../components/drawer';
import { LineChart, Line, BarChart, Bar, Tooltip, Legend, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import colors from '../utils/colors';
import formatToClp from '../utils/format_to_clp';
import { fetchDataPaysStudents } from '../database/pays';

const Home = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [data, setData] = useState([]);
  const [lastThreeDaysData, setLastThreeDaysData] = useState([]);
  const [totalRecaudado, setTotalRecaudado] = useState(0);
  const [totalRecaudadoMes, setTotalRecaudadoMes] = useState(0);
  const [totalRecaudadoHoy, setTotalRecaudadoHoy] = useState(0);





  useEffect(() => {
    async function getData() {
      const processedData = await fetchDataPaysStudents();
      setData(processedData.data);
      setTotalRecaudado(processedData.totalRecaudado);
      setTotalRecaudadoMes(processedData.totalRecaudadoMes);
      setTotalRecaudadoHoy(processedData.totalRecaudadoHoy);
      setLastThreeDaysData(processedData.lastThreeDaysData)

      // setTotalPendientes(processedData.totalPendientes);
      // setTotalDelMes(processedData.totalDelMes);
    }

    getData();

  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100vw',
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'home'} />
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        overflowX: 'hidden'
      }}>
        <Box sx={{
          width: '90%',
          textAlign: 'start'
        }}>
          <Typography textAlign={'left'} variant='h4' fontFamily={'Nunito'} marginBottom={'10px'} color={colors.blue}>Inicio</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px',
          width: '90%',
        }}>
          <Box sx={{
            width: '45%',
            borderRadius: '10px',
            padding: '10px',
            backgroundColor: '#06314E17'
          }}>

            <h2 style={{
              color: colors.blue,
              textAlign: 'start',
              marginLeft: '20px'
            }}>Pagos últimos 3 días</h2>

            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={lastThreeDaysData}>
                <defs>
                  <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={colors.blue} stopOpacity={1} />
                    <stop offset="100%" stopColor={colors.blue} stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Line type="monotone" dataKey="cantidadPagos" stroke="url(#lineGradient)" strokeWidth={3} dot={{ r: 5 }} />
              </LineChart>
            </ResponsiveContainer>

          </Box>
          <Box sx={{
            width: '45%',
            backgroundColor: '#06314E17',
            borderRadius: '10px',
            padding: '10px',
          }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'start',
              marginLeft: '20px'
            }}>Pagos del mes</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <defs>
                  <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={colors.blue} stopOpacity={0.8} />
                    <stop offset="100%" stopColor={colors.blue} stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="cantidadPagos" fill="url(#barGradient)" />
              </BarChart>
            </ResponsiveContainer>

          </Box>
        </Box>

        <Box style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '90%',
        }}>
          <Box
            display="flex"
            flex={1}
            height={300}
            justifyItems="center"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginRight: '10px',
              // width: '45%',
              backgroundColor: '#06314E17',
              borderRadius: '10px',
              padding: '20px',

            }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Cantidad de hoy recaudada</h2>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={300}>
              <AttachMoneyIcon style={{ fontSize: 80, color: colors.greenAqua }} />
              <Typography variant="h4" style={{ color: colors.greenAqua }}>
                {formatToClp(totalRecaudadoHoy)}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flex={1}
            height={300}
            justifyItems="center"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginRight: '10px',
              // width: '45%',
              backgroundColor: '#06314E17',
              borderRadius: '10px',
              padding: '20px',
            }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Cantidad del mes recaudada</h2>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={300}>
              <AttachMoneyIcon style={{ fontSize: 80, color: colors.greenAqua }} />
              <Typography variant="h4" style={{ color: colors.greenAqua }}>
                {formatToClp(totalRecaudadoMes)}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flex={1}
            height={300}
            justifyItems="center"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{

              marginRight: '10px',
              // width: '45%',
              backgroundColor: '#06314E17',
              borderRadius: '10px',
              padding: '20px',

              justifyContent: 'center',
            }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Cantidad total recaudada</h2>
            <Box display="flex" justifyItems="center" flexDirection="column" alignItems="center" justifyContent="center" height={300}>
              <AttachMoneyIcon style={{ fontSize: 80, color: colors.greenAqua }} />
              <Typography variant="h4" style={{ color: colors.greenAqua }}>
                {formatToClp(totalRecaudado)}
              </Typography>
            </Box>
          </Box>
        </Box>


      </Box>
    </div>
  );
}

export default Home;
