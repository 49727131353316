import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';
import axios from 'axios';

export const savePay = async (idFamily, datePayed, amount, ref, date) => {
    try {
        const docRes = doc(db, 'families', idFamily);
        const docSnap = await getDoc(docRes);

        if (docSnap.exists()) {
            const data = docSnap.data();
            console.log('Datos del documento:', data);

            if (data.payed === true) {
                console.log('El pago ya ha sido realizado.');
                return "already payed";
            }

            await updateDoc(docRes, {
                payed: true,
                datePayed: datePayed
            });

            console.log({
                subject: "Pago realizado | La Salle",
                amount: amount,
                refCode: ref,
                date: date,
                email: data.email
            });

            await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendEmailBrevo',
                { subject: "Pago realizado | La Salle", amount: amount, refCode: ref, date: capitalizeWords(date), email: data.email }
            );

            console.log('Documento actualizado exitosamente');

            return 'updated';
        } else {
            console.log('El documento no existe.');
        }

    } catch (error) {
        console.error(error);
        return "error";
    }
};

export const getValuePay = async () => {
    try {
        const docRef = doc(db, 'values', 'jSaNocG6rOcs0uX9Ryju');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data().value;
        } else {
            console.log('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error getting value for POS:', error);
        return null;
    }
}

export const updateValuePay = async (value) => {
    if (isNaN(value)) {
        console.error('Invalid value for POS:', value);
        return false;
    } else {
        try {
            const docRef = doc(db, 'values', 'jSaNocG6rOcs0uX9Ryju');
            await updateDoc(docRef, { value: parseInt(value), lastUpdate: serverTimestamp() });
            return true;
        } catch (error) {
            console.error('Error updating value for POS:', error);
            return false;
        }
    }
};

export const checkPayApoderadoByRut = async (rut) => {
    try {
        const q = query(collection(db, "pays_students"), where("rutApoderado", "==", rut));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log("error");
        return 'error';
    }
}


export const savePayment = async (students, rut, name, email, reference, signature, timestamp) => {
    console.log(students);


    try {

        const q = query(collection(db, "pays_students"), where("rutApoderado", "==", rut));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            console.log("Ya existe un documento con la referencia proporcionada.");
            return 'exists';
        }
        // Crea el documento en Firestore
        const docRef = await addDoc(collection(db, "pays_students"), {
            students: students,
            rutApoderado: rut,
            nameApoderado: name,
            emailApoderado: email,
            reference: reference,
            payedAt: new Date(), // Fecha actual
            signature: signature,
            status: "payed",
            type: "transbank",
            timestamp: timestamp
        });

        console.log("Documento guardado con ID: ", docRef.id);
        return true;
    } catch (e) {
        console.error("Error al guardar el documento: ", e);
        return false;
    }
};


export const fetchDataPaysStudents = async () => {
    const paysCollection = collection(db, 'pays_students');
    const paysSnapshot = await getDocs(paysCollection);
    const paysData = paysSnapshot.docs.map(doc => doc.data());

    const processedData = processStatistics(paysData);
    console.log(processedData);
    
    return processedData;
};



const processStatistics = (paysData) => {
    const monthlyData = [];
    let totalRecaudado = 0;       // Cantidad total recaudada
    let totalRecaudadoHoy = 0;    // Cantidad recaudada hoy
    let totalRecaudadoMes = 0;    // Cantidad recaudada en el mes actual
    let totalPendientes = 0;      // Cantidad total de pagos pendientes
    let totalCantidadPagos = 0;   // Total de pagos en número de registros
    let cantidadPagosHoy = 0;     // Total de pagos realizados hoy
    const lastThreeDaysData = []; // Datos de pagos para los últimos tres días

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    
    // Calcula el índice del mes actual y el día actual
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Define los índices de los últimos 4 meses (el mes actual y los tres anteriores)
    const recentMonths = [
      (currentMonthIndex - 3 + 12) % 12, // Hace 3 meses
      (currentMonthIndex - 2 + 12) % 12, // Hace 2 meses
      (currentMonthIndex - 1 + 12) % 12, // Hace 1 mes
      currentMonthIndex,                 // Mes actual
    ];

    // Procesa los datos solo de los últimos 4 meses
    recentMonths.forEach((monthIndex) => {
      const monthPayments = paysData.filter(pay => {
        if (!pay.payedAt) return false;

        // Convierte pay.payedAt a una fecha de JavaScript si es un Timestamp
        let payedAtDate;
        if (pay.payedAt.toDate) {
          payedAtDate = pay.payedAt.toDate();
        } else {
          payedAtDate = new Date(pay.payedAt);
        }

        // Si la fecha es inválida, excluye este registro
        if (isNaN(payedAtDate.getTime())) return false;

        return payedAtDate.getMonth() === monthIndex && payedAtDate.getFullYear() === currentDate.getFullYear();
      });

      // Calcula la cantidad de pagos realizados y pendientes por mes
      const pagosRealizados = monthPayments.filter(pay => pay.status === "payed").length;
      const pagosPendientes = monthPayments.filter(pay => pay.status !== "payed").length;

      // Suma al total recaudado el número de pagos realizados multiplicado por 33,000 CLP
      const monthRecaudado = pagosRealizados * 33000;
      const monthPendientes = pagosPendientes * 33000;

      // Acumula los totales generales
      totalRecaudado += monthRecaudado;
      totalPendientes += monthPendientes;
      totalCantidadPagos += pagosRealizados;  // Suma al total de número de pagos

      // Acumula el total del mes actual si corresponde
      if (monthIndex === currentMonthIndex) {
        totalRecaudadoMes += monthRecaudado;
      }

      monthlyData.push({
        name: months[monthIndex],
        pendientes: monthPendientes,
        delMes: monthRecaudado,
        totalRecaudado: monthRecaudado,
        mesRecaudado: monthRecaudado,
        cantidadPagos: pagosRealizados,  // Cantidad de pagos en el mes en términos de registros
      });
    });

    // Procesa el total de hoy y los pagos de los últimos tres días
    for (let i = 0; i < 4; i++) {
        const targetDate = new Date(currentDate);
        targetDate.setDate(currentDate.getDate() - i);

        const pagosDelDia = paysData.filter(pay => {
            if (!pay.payedAt || pay.status !== "payed") return false;

            let payedAtDate;
            if (pay.payedAt.toDate) {
                payedAtDate = pay.payedAt.toDate();
            } else {
                payedAtDate = new Date(pay.payedAt);
            }

            return (
                payedAtDate.getDate() === targetDate.getDate() &&
                payedAtDate.getMonth() === targetDate.getMonth() &&
                payedAtDate.getFullYear() === targetDate.getFullYear()
            );
        });

        const pagosDelDiaCount = pagosDelDia.length;
        const recaudadoDelDia = pagosDelDiaCount * 33000;

        // Suma al total de hoy si es la fecha actual
        if (i === 0) {
            totalRecaudadoHoy += recaudadoDelDia;
            cantidadPagosHoy += pagosDelDiaCount;
        }

        // Agrega los datos al array de los últimos tres días
        lastThreeDaysData.unshift({
            date: targetDate.toLocaleDateString("es-ES", { day: '2-digit', month: '2-digit' }),
            cantidadPagos: pagosDelDiaCount
        });
    }

    console.log("Resultados de estadística:", {
      data: monthlyData,
      totalRecaudado,
      totalRecaudadoMes,
      totalRecaudadoHoy,
      totalPendientes,
      totalCantidadPagos,
      cantidadPagosHoy,
      lastThreeDaysData,
    });

    return {
      data: monthlyData,            // Datos de cada mes con pendientes y recaudado
      totalRecaudado,               // Cantidad total recaudada
      totalRecaudadoMes,            // Cantidad recaudada en el mes actual
      totalRecaudadoHoy,            // Cantidad recaudada hoy
      totalPendientes,              // Cantidad total pendiente
      totalCantidadPagos,           // Total de pagos en número de registros
      cantidadPagosHoy,             // Cantidad de pagos realizados hoy
      lastThreeDaysData,            // Datos de pagos para los últimos tres días
    };
  };
